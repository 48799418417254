import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';




class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: -5,
            reklamacia_id: "",
            reklamacia_phone_number: "",
            reklamacia_email: "",
            reklamacia_text: "",
            reklamacia_sposob: "kupon",
            reklamacia_gdpr: false,
            zrusenie_id: "",
            zrusenie_phone: "",
            zrusenie_mail: "",
            zrusenie_text: "",
            zrusenie_gdpr: false,

            dotaz_id: "",
            dotaz_phone: "",
            dotaz_mail: "",
            dotaz_text: "",
            dotaz_gdpr: false,
            iban: "",
            fotka: new Array(),


        }
        this.handleDotazGdpr = this.handleDotazGdpr.bind(this);
        this.handleReklamaciaIdChange = this.handleReklamaciaIdChange.bind(this);
        this.handleReklamaciaPhoneChange = this.handleReklamaciaPhoneChange.bind(this);
        this.handleReklamaciaEmailChange = this.handleReklamaciaEmailChange.bind(this);
        this.handleReklamaciaTextChange = this.handleReklamaciaTextChange.bind(this);
        this.handleReklamaciaSposobChange = this.handleReklamaciaSposobChange.bind(this);
        this.handleReklamacia = this.handleReklamacia.bind(this);
        this.handleZrusenie = this.handleZrusenie.bind(this);
        this.handleIban = this.handleIban.bind(this);

        this.handleZrusenieId = this.handleZrusenieId.bind(this);
        this.handleZruseniePhone = this.handleZruseniePhone.bind(this);
        this.handleZrusenieMail = this.handleZrusenieMail.bind(this);
        this.handleZrusenieText = this.handleZrusenieText.bind(this);

        this.handleDotaz = this.handleDotaz.bind(this);

        this.handleDotazId = this.handleDotazId.bind(this);
        this.handleDotazPhone = this.handleDotazPhone.bind(this);
        this.handleDotazMail = this.handleDotazMail.bind(this);
        this.handleDotazText = this.handleDotazText.bind(this);
        this.changeType = this.changeType.bind(this);
        this.handleFotka = this.handleFotka.bind(this);
        this.handleReklamaciaGdpr =this.handleReklamaciaGdpr.bind(this);
        this.handleZrusenieGdpr =this.handleZrusenieGdpr.bind(this);
        this.delimko = this.delimko.bind(this);

    }

    componentDidMount(){
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();
        window.scroll(0,0);
    }


    handleDotazGdpr(){

        this.setState({
            dotaz_gdpr: !this.state.dotaz_gdpr,
        })
    }
    handleIban(event){




        var handler= 0;

        if(event.target.value.length>=25){

            var iban = "";
            for (var x = 0; x < event.target.value.length; x++)
            {
                var c = event.target.value.charAt(x);
                if(c != " ") {
                    iban = iban + c;
                }
            }
            this.setState({
                iban: iban,
            });
            handler = 1;
        }
        else {


            if (event.target.value.length == 25) {
                NotificationManager.warning("", "IBAN môže obsahovať iba 24 znakov");
                return;
            }

            if (event.target.value.length == 1) {
                if (event.target.value != "S") {
                    NotificationManager.warning("", "IBAN musí začínať písmenom S");
                    return;
                }
            }
            if (event.target.value.length == 2) {
                if (event.target.value != "SK") {
                    NotificationManager.warning("", "IBAN musí začínať slovom SK");
                    return;
                }
            }
            if (event.target.value.length > 2 && isNaN(event.target.value[event.target.value.length - 1])) {
                if (event.target.value) {
                    NotificationManager.warning("", "IBAN môže obsahovať iba číslice");
                    return;
                }
            }
            if (event.target.value.length > 2 && event.target.value[event.target.value.length - 1] == " ") {
                if (event.target.value) {
                    NotificationManager.warning("", "IBAN nemôže obsahovať medzery");
                    return;
                }
            }


            if (handler == 0) {

                this.setState({
                    iban: event.target.value,
                })
            }
        }



    }
    handleReklamaciaGdpr(){
        this.setState({
            reklamacia_gdpr: !this.state.reklamacia_gdpr,
        })
    }
    handleZrusenieGdpr(){
        this.setState({
            zrusenie_gdpr: !this.state.zrusenie_gdpr,
        })
    }

    handleFotka(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
           // console.log(e.target.result);

            var image = new Array();

            image.push({path: file.name, data: e.target.result})

            var foto = this.state.fotka;
            foto.push(image);

         //   console.log(foto);

            this.setState({
                fotka: foto,
            })
        };

        reader.readAsDataURL(file);

    }

    changeType(id){
        this.setState({
            type: id,
        })
    }

    handleDotazId(event){
        this.setState({
            dotaz_id: event.target.value,
        })
    }
    handleDotazPhone(event){
        if(event.target.value.length > 10){
            NotificationManager.warning("", "Tel. číslo môže obsahovať iba 10 znakov");
            return;
        }

        if(this.state.dotaz_phone == ""){
            if(event.target.value != 0){
                NotificationManager.warning("","Tel. číslo musí začínať 0 (tvar 09XXXXXXXX)!");
                return;
            }
        }

        if(this.state.dotaz_phone.length == 1){
            if(event.target.value != 9){
                NotificationManager.warning("","Tel. číslo musí začínať 09 (tvar 09XXXXXXXX)!");
                return;
            }
        }
        if(event.target.value == " "){
            NotificationManager.warning("", "Tel. číslo nesmie obsahovať medzeru!");
            return;
        }

        this.setState({dotaz_phone: event.target.value.replace(/\s+/g, '')});


    }
    handleDotazMail(event){
        this.setState({
            dotaz_mail: event.target.value,
        })
    }
    handleDotazText(event){
        this.setState({
            dotaz_text: event.target.value,
        })
    }

    handleDotaz(event){

        if(this.state.dotaz_id == "" && this.state.dotaz_phone == ""){
            NotificationManager.warning("","ID alebo tel. číslo je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.dotaz_mail == "" ){
            NotificationManager.warning("","E-Mail je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.dotaz_text == ""){
            NotificationManager.warning("","Text je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.dotaz_gdpr == false){
            NotificationManager.warning("","Musíte súhlasiť s podmienkami", 3000);
            event.preventDefault();
            return;
        }
        var datas = {
            "id" : this.state.dotaz_id,
            "phone" : this.state.dotaz_phone,
            "email" : this.state.dotaz_mail,
            "text": this.state.dotaz_text,
        }
        axios.post("https://notifysystem.sladuckeovocie.sk/api/dotaz", datas).then(datas=>{
            NotificationManager.info("", "Ďakujeme, správa bola úspešné odoslaná", 3000);
        //    console.log(datas);
        });
        // console.log("dotaz");
        // console.log(this.state.dotaz_id);
        // console.log(this.state.dotaz_mail);
        // console.log(this.state.dotaz_phone);
        // console.log(this.state.dotaz_text);

        this.setState({
            dotaz_id: "",
            dotaz_phone: "",
            dotaz_mail: "",
            dotaz_text: "",
            dotaz_gdpr: false,
        });

        event.preventDefault();
    }

    handleZrusenie(event){
        if(this.state.zrusenie_id == "" && this.state.zrusenie_phone == ""){
            NotificationManager.warning("","ID alebo tel. číslo je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.zrusenie_mail == "" ){
            NotificationManager.warning("","E-Mail je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.zrusenie_text == ""){
            NotificationManager.warning("","Text je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.zrusenie_gdpr == false){
            NotificationManager.warning("","Musíte súhlasiť s podmienkami", 3000);
            event.preventDefault();
            return;
        }

        var datas = {
            "id" : this.state.zrusenie_id,
            "phone" : this.state.zrusenie_phone,
            "email" : this.state.zrusenie_mail,
            "text": this.state.zrusenie_text,
        }
        axios.post("https://notifysystem.sladuckeovocie.sk/api/zrusenie", datas).then(datas=>{
            NotificationManager.info("", "Ďakujeme, správa bola úspešné odoslaná", 3000);
       //     console.log(datas);
        });
        // console.log(datas);
        //
        // console.log("zrusenie");
        // console.log(this.state.zrusenie_id);
        // console.log(this.state.zrusenie_mail);
        // console.log(this.state.zrusenie_phone);
        // console.log(this.state.zrusenie_text);
        this.setState({
            zrusenie_id: "",
            zrusenie_phone: "",
            zrusenie_mail: "",
            zrusenie_text: "",
            zrusenie_gdpr: false,
        });
        event.preventDefault();
    }
    handleZrusenieId(event){
        this.setState({
            zrusenie_id: event.target.value,
        })
    }
    handleZruseniePhone(event){
        if(event.target.value.length > 10){
            NotificationManager.warning("", "Tel. číslo môže obsahovať iba 10 znakov");
            return;
        }

        if(this.state.zrusenie_phone == ""){
            if(event.target.value != 0){
                NotificationManager.warning("","Tel. číslo musí začínať 0 (tvar 09XXXXXXXX)!");
                return;
            }
        }

        if(this.state.zrusenie_phone.length == 1){
            if(event.target.value != 9){
                NotificationManager.warning("","Tel. číslo musí začínať 09 (tvar 09XXXXXXXX)!");
                return;
            }
        }
        if(event.target.value == " "){
            NotificationManager.warning("", "Tel. číslo nesmie obsahovať medzeru!");
            return;
        }

        this.setState({zrusenie_phone: event.target.value.replace(/\s+/g, '')});


    }
    handleZrusenieMail(event){
        this.setState({
            zrusenie_mail: event.target.value,
        })
    }
    handleZrusenieText(event){
        this.setState({
            zrusenie_text: event.target.value,
        })
    }

    handleReklamacia(event){




        if(this.state.reklamacia_id == "" && this.state.reklamacia_phone_number == ""){
            NotificationManager.warning("","ID alebo tel. číslo je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.reklamacia_email == ""){
            NotificationManager.warning("","E-Mail je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.reklamacia_text == ""){
            NotificationManager.warning("","Text je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.reklamacia_gdpr == false){
            NotificationManager.warning("","Musíte súhlasiť s podmienkami", 3000);
            event.preventDefault();
            return;
        }

        if(this.state.reklamacia_sposob == "ucet" && this.state.iban == ""){
            NotificationManager.warning("","IBAN je povinná položka", 3000);
            event.preventDefault();
            return;
        }
        if(this.state.reklamacia_sposob == "ucet" && this.state.iban.length != 24){
            NotificationManager.warning("","IBAN je v nesprávnom tvare", 3000);
            event.preventDefault();
            return;
        }



        // console.log(this.state.reklamacia_id);
        // console.log(this.state.reklamacia_phone_number);
        // console.log(this.state.reklamacia_email);
        // console.log(this.state.reklamacia_text);
        // console.log(this.state.reklamacia_sposob);
        // console.log(this.state.fotka);

        var datas = {
            "id" : this.state.reklamacia_id,
            "phone" : this.state.reklamacia_phone_number,
            "email" : this.state.reklamacia_email,
            "text": this.state.reklamacia_text,
            "fotka": this.state.fotka,
            "iban": this.state.iban,
            "sposob": this.state.reklamacia_sposob,
        }
        axios.post("https://notifysystem.sladuckeovocie.sk/api/reklamacia", datas).then(datas=>{
            NotificationManager.info("", "Ďakujeme, správa bola úspešné odoslaná", 3000);
        //   console.log(datas);
        }).catch(error => {
          //  console.log(error);
        });
       // console.log(datas);

        this.setState({
            reklamacia_id: "",
            reklamacia_phone_number: "",
            reklamacia_email: "",
            reklamacia_text: "",
            fotka: new Array(),
            iban: "",

            reklamacia_gdpr: false,
        });
        event.preventDefault();

    }

    delimko(index){
        var arko = this.state.fotka;
        var x=0;
        var newar = new Array();
        arko.forEach(function (e) {
            if(x!=index){
                newar.push(e);
            }
            x++;
        }.bind(this));
        this.setState({
            fotka: newar
        })
    }

    handleReklamaciaIdChange(event){
        this.setState({
            reklamacia_id: event.target.value,
        })
    }
    handleReklamaciaText(event){
        this.setState({
            reklamacia_text: event.target.value,
        })
    }
    handleReklamaciaPhoneChange(event){

        if(event.target.value.length > 10){
            NotificationManager.warning("", "Tel. číslo môže obsahovať iba 10 znakov");
            return;
        }

        if(this.state.reklamacia_phone_number == ""){
            if(event.target.value != 0){
                NotificationManager.warning("","Tel. číslo musí začínať 0 (tvar 09XXXXXXXX)!");
                return;
            }
        }

        if(this.state.reklamacia_phone_number.length == 1){
            if(event.target.value != 9){
                NotificationManager.warning("","Tel. číslo musí začínať 09 (tvar 09XXXXXXXX)!");
                return;
            }
        }
        if(event.target.value == " "){
            NotificationManager.warning("", "Tel. číslo nesmie obsahovať medzeru!");
            return;
        }

        this.setState({reklamacia_phone_number: event.target.value.replace(/\s+/g, '')});


    }
    handleReklamaciaEmailChange(event){
        this.setState({
            reklamacia_email: event.target.value,
        })
    }
    handleReklamaciaTextChange(event){
        this.setState({
            reklamacia_text: event.target.value,
        });
    }
    handleReklamaciaSposobChange(event){
        this.setState({
            reklamacia_sposob: event.target.value,
        })
        if(event.target.value == "vymena"){
            NotificationManager.warning("", "Budeme Vás kontaktovať podľa dostupnosti tovaru", 3000);
        }
    }






    render() {
        return(
            <div className="form">
                <NotificationContainer/>
                <div className="container">
                    <div className="row ods odsbot kosods">
                        <div className="col-md-12">

                            <h4 class="headline">Kontaktný formulár</h4>
                            <p className="center">Pred tým ako nám napíšete, si môžete prečítať <Link to="/faq">odpovede na najčastejšie kladené otázky</Link>.</p>
                            <p className="center">Zvoľte si požiadavku, ktorú chcete riešiť. Formulár neslúži na objednávku.</p>


                            <br/>
                            <div className="cental">
                                <a onClick={()=>this.changeType(0)} className="btn btn-primary martop">Reklamácia</a>&nbsp;&nbsp;<a onClick={()=>this.changeType(1)} className="btn btn-primary martop">Odobratie / zrušenie objednávky</a>&nbsp;&nbsp;<a onClick={()=>this.changeType(2)} className="btn btn-primary martop">Dotaz k objednávke</a>
                            </div>
                                <br/><br/>

                            { this.state.type == 0 ?

                                <div>
                                    <h1 className="nadpis">Reklamácia</h1>
                                    <form onSubmit={this.handleReklamacia}>
                                        <div className="form-group">
                                            <label>ID objednávky* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input className="form-control" type="text" value={this.state.reklamacia_id} onChange={this.handleReklamaciaIdChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Tel. číslo* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input placeholder="Tvar: 09XXXXXXXX" className="form-control" type="text" value={this.state.reklamacia_phone_number} onChange={this.handleReklamaciaPhoneChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>E-Mail*</label>
                                            <input className="form-control" type="text" value={this.state.reklamacia_email} onChange={this.handleReklamaciaEmailChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Text*</label>
                                            <textarea className="form-control" type="text" value={this.state.reklamacia_text} onChange={this.handleReklamaciaTextChange}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Fotka (urýchlenie procesu reklamácie)</label>
                                            <input className="form-control" type="file"  onChange={this.handleFotka} />
                                            <div className="fotky1">

                                            {this.state.fotka.map((data, index) =>
                                                <p>{index+1}. {data[0].path}<a onClick={()=>this.delimko(index)} className="delim">X</a></p>
                                            )}
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label>Preferovaný spôsob</label>
                                            <select onChange={this.handleReklamaciaSposobChange} className="form-control"><option value="kupon">Kupón</option><option value="vymena">Výmena tovaru</option><option value="ucet">Vrátenie peňazí na Váš účet</option></select>
                                        </div>

                                        {this.state.reklamacia_sposob == 'ucet' ?
                                            <div className='form-group'>
                                                <label>IBAN*</label>
                                                <input className="form-control" type="text" value={this.state.iban}  onChange={this.handleIban} />
                                            </div>

                                            :
                                            null

                                        }
                                        <div className="form-group">
                                            <input type="checkbox" onChange={this.handleReklamaciaGdpr} checked={this.state.reklamacia_gdpr} /> <span>SÚHLASÍM SO SPRACOVANÍM OSOBNÝCH ÚDAJOV V SÚLADE S NARIADENÍM <Link className="gdprform" target="_blank" to="/ochrana-sukromia">GDPR O OCHRANE OSOBNÝCH ÚDAJOV*</Link></span>
                                        </div>
                                        <input type="submit" value="Odoslať" className="btn btn-primary" />
                                    </form>
                                </div> : null

                            }
                            { this.state.type == 1 ?
                                <div>
                                    <h1 className="nadpis">Odobratie / zrušenie objednávky</h1>
                                    <form onSubmit={this.handleZrusenie}>
                                        <div className="form-group">
                                            <label>ID objednávky* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input className="form-control" type="text" value={this.state.zrusenie_id} onChange={this.handleZrusenieId}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Tel. číslo* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input placeholder="Tvar: 09XXXXXXXX" className="form-control" type="text" value={this.state.zrusenie_phone} onChange={this.handleZruseniePhone}/>
                                        </div>
                                        <div className="form-group">
                                            <label>E-Mail*</label>
                                            <input className="form-control" type="text" value={this.state.zrusenie_mail} onChange={this.handleZrusenieMail}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Text*</label>
                                            <textarea className="form-control" type="text" value={this.state.zrusenie_text} onChange={this.handleZrusenieText}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" onChange={this.handleZrusenieGdpr} checked={this.state.zrusenie_gdpr} /> <span>SÚHLASÍM SO SPRACOVANÍM OSOBNÝCH ÚDAJOV V SÚLADE S NARIADENÍM <Link className="gdprform" target="_blank" to="/ochrana-sukromia">GDPR O OCHRANE OSOBNÝCH ÚDAJOV*</Link></span>
                                        </div>
                                        <input type="submit" value="Odoslať" className="btn btn-primary" />
                                    </form>
                                </div>
                            : null
                            }
                            { this.state.type == 2 ?
                                <div>
                                    <h1 className="nadpis">Dotaz k objednávke</h1>
                                    <form onSubmit={this.handleDotaz}>
                                        <div className="form-group">
                                            <label>ID objednávky* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input className="form-control" type="text" value={this.state.dotaz_id} onChange={this.handleDotazId}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Tel. číslo* (povinné je ID objednávky alebo tel. číslo)</label>
                                            <input placeholder="Tvar: 09XXXXXXXX" className="form-control" type="text" value={this.state.dotaz_phone} onChange={this.handleDotazPhone}/>
                                        </div>
                                        <div className="form-group">
                                            <label>E-Mail*</label>
                                            <input className="form-control" type="text" value={this.state.dotaz_mail} onChange={this.handleDotazMail}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Text*</label>
                                            <textarea className="form-control" type="text" value={this.state.dotaz_text} onChange={this.handleDotazText}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" onChange={this.handleDotazGdpr} checked={this.state.dotaz_gdpr} /> <span>SÚHLASÍM SO SPRACOVANÍM OSOBNÝCH ÚDAJOV V SÚLADE S NARIADENÍM <Link className="gdprform" target="_blank" to="/ochrana-sukromia">GDPR O OCHRANE OSOBNÝCH ÚDAJOV*</Link></span>
                                        </div>
                                        <input type="submit" value="Odoslať" className="btn btn-primary" />
                                    </form>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Form;

