import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import BackBtn from "../iconmonstr-undo-4.svg";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';




class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meno: "",
            cislo: "",
            sprava: "",
            email: "",
            obsah: "",
            text: "",
        };


    }


    componentDidMount(){
        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {



                this.setState({jsonAcf : res.data,

                    obsah: res.data[1]['acf']['partneri'],
                    text: res.data[1]['acf']['partneri_text']

                });

            });

        window.scrollTo(0, 0)
    }


    render() {

        return (
            <div className="vop">

                <section>
                    <div className="mainImage" style={{  backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text">
                                        O nás
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row ods odsbot">
                            <div className="col-md-12">
                                <div className="obal" dangerouslySetInnerHTML={{__html: this.state.text}}/>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );

    }
}

export default About;

