import React, { Component } from 'react';
import Header from '../Header';
import ReactDOM from 'react-dom';
import ProductModal from '../Product/ProductModal'
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { Button,Modal } from 'react-bootstrap';
import Product from "../Product/Product";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import BackBtn from "../iconmonstr-undo-4.svg";
import Cart from "../iconmonstr-shopping-cart-3.svg";
import Back from "../iconmonstr-arrow-64.svg";
import Next from "../iconmonstr-arrow-25.svg";
var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});


let state = "";
let activevat = "";
let catname = "";
const mainimg = "https://www.sladuckeovocie.sk/wp-content/uploads/2020/05/Untitled-1.png";
class Homepage extends Component{

    slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }



    constructor(props){
        super(props);


            this.state = {
                mainImage: "",
                jsonAcf: "",
                detail: false,
                mainText: "",
                promo: [],
                shop: "",
                detailId: 0,
                products: [],
                productCategories: "nic",
                varko: "",
                showClass: 0,
                menu: false,
                odporucane: "",
                oznam: "",
                oznam2: true,
                stock: "",
                categ: "",
                data: "",
                isMobile: false,
                handler: false,
                firstcat: "",
                chliev: false,
                categoriesl: "",
                sllug:"",
                activecat: activevat,
                nextcat: "",
                prevcat: "",
                catname: catname,
                allowedscroll: false,
                cats: "",
                loadedcat: 0,
                show: false,
                adult: false,

            };


            this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.goback = this.goback.bind(this);
        this.not = this.not.bind(this);
        this.cancelnot = this.cancelnot.bind(this);
        this.nextcata = this.nextcata.bind(this);
        this.backcat = this.backcat.bind(this);
        this.gotocat = this.gotocat.bind(this);
        this.cancel = this.cancel.bind(this);
        this.showw = this.showw.bind(this);
        this.chlievik = this.chlievik.bind(this);
        this.backtoall = this.backtoall.bind(this);
        this.setAdult = this.setAdult.bind(this);
        this.getvisibility = this.getvisibility.bind(this)





    }

    handleClose = () => {

        this.setState({
            show:false
        })
    }
    handleShow= (slug) => {
        console.log("called");

        this.setState({
            sllug:slug,
        })

        this.setState(
            {
                sllug:slug,
            },
            function() { this.setState({
                show:true,
            }) }
        )



    }
    backtoall(){
        this.setState({
            activecat: "",
            allowedscroll: true,
        })
    }

    setAdult = () => {

        this.setState({
            adult: true,
        })
        this.props.setAdult();
    }

    showw(){
        this.setState({
            menu: false,
            handler: false,
        })
    }
    cancel(){


        this.setState({
            menu: true,
            handler: true,
        })
    }
    chlievik(){

        this.setState({
            menu: !this.state.menu,
        })
    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        var items = window.location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    scrolltoel = () =>{



        if(this.state.allowedscroll == true) {
           // alert("test")

            let el = "#catname";
            scrollToElement(el, {offset: -140, duration: 0,});
            this.setState({allowedscroll: false})
        }

    }

    not(id){


        this.setState({detail: true,
            detailId : id
        })
    }
    goback(){
        this.setState({detail: false});
    }

    getvisibility(id){
        var visibility = "";

        this.state.categoriesl.forEach(function (e) {
           if(e.id == id){
               visibility=e.display;
           }
        }.bind(this));
        console.log(visibility);
        return visibility;
    }

    gotocat(id,name){
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };

        var self = this;
        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?category="+ id +"&per_page=99&status=publish";
        self.setState({
            activecat: id,

        });



        if(self.state.loadedcat == 0){
            this.setState({
                loadedcat: 1,
            })
        }

        axios.get(url, config).then(data1=> {
            var prod = data1.data;
            prod.forEach(function (e) {
            //    console.log("e");
                e.sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title4");
                e.sale = e.sale.value;
                e.sale_percentage = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title10");
                e.sale_percentage = e.sale_percentage.value;
                e.off = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title5");
                e.off = e.off.value;
                e.uvadzacia_akcia = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title11");
                e.uvadzacia_akcia = e.uvadzacia_akcia.value;
                e.body = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title12");
                e.body = e.body.value;
                e.exclusive = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title13");
                e.exclusive = e.exclusive.value;
                e.trvanlivost= this.findArrayElementByTitle(e.meta_data, "custom_text_field_title22");
                //e.featured_src = "";

                if(Object.keys(e.images).length > 0) {
                    e.featured_src = e.images[0].src;
                }
                else{
                    e.featured_src = "";
                }

                e.title = e.name;
                e.custom_field = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title");
                e.custom_field = e.custom_field.value;
                e.subtitle = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title2");
                e.subtitle = e.subtitle.value;
                e.extra_sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title25");

                if(e.extra_sale != undefined) {
                    e.extra_sale = e.extra_sale.value;
                }

            }.bind(this));



            this.setState({data: data1.data, activecat: id, catname: name, allowedscroll: true, loadedcat: 0});
               console.log(data1);
        });


    }

    backcat(){
        var curcat = this.state.activecat;
        var x=0;
        window.scrollTo(0, 0);
        if(curcat == this.state.categoriesl[0].id){
            this.gotocat(this.state.categoriesl[this.state.categoriesl.length -1].id,this.state.categoriesl[this.state.categoriesl.length -1].name );
            return;
        }
        this.state.categoriesl.forEach(function (e) {
            if(e.id == curcat){

                var y=0;
                this.state.categoriesl.forEach(function (e) {
                    if(x-1 == y){
                        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
                        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

                        var authorizationBasic = window.btoa(uname + ':' + pass);
                        var config = {
                            "headers": {
                                "Authorization": "Basic " + authorizationBasic
                            }
                        };

                        var self = this;
                        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?category="+ e.id +"&per_page=99&status=publish";
                   //     console.log(url);
                        if(e.count==0 || e.id == "3657"){
                            this.setState({
                                activecat: e.id, catname: e.name,
                            });
                            this.nextcata();
                        }
                        self.setState({
                            activecat: e.id,
                        });




                        axios.get(url, config).then(data1=> {
                            var prod = data1.data;
                            prod.forEach(function (e) {
               //                 console.log("e");
                                e.sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title4");
                                e.sale = e.sale.value;
                                e.sale_percentage = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title10");
                                e.sale_percentage = e.sale_percentage.value;
                                e.off = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title5");
                                e.off = e.off.value;
                                e.uvadzacia_akcia = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title11");
                                e.uvadzacia_akcia = e.uvadzacia_akcia.value;
                                e.body = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title12");
                                e.body = e.body.value;
                                e.exclusive = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title13");
                                e.exclusive = e.exclusive.value;
                                //e.featured_src = "";

                                if(Object.keys(e.images).length > 0) {
                                    e.featured_src = e.images[0].src;
                                }
                                else{
                                    e.featured_src = "";
                                }

                                e.title = e.name;
                                e.trvanlivost= this.findArrayElementByTitle(e.meta_data, "custom_text_field_title22");
                                e.custom_field = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title");
                                e.custom_field = e.custom_field.value;
                                e.subtitle = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title2");
                                e.subtitle = e.subtitle.value;
                                e.extra_sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title25");
                                if(e.extra_sale != undefined) {
                                    e.extra_sale = e.extra_sale.value;
                                }

                            }.bind(this));

                            self.setState({data: data1.data, activecat: e.id, catname: e.name, allowedscroll: true});
                        //    console.log(data1);
                        });

                    }
                    y++;
                }.bind(this));
            }
            x++;

        }.bind(this));

    }
    nextcata(){
        var curcat = this.state.activecat;
        var x=0;
        window.scrollTo(0, 0);
        console.log(this.state.categoriesl[this.state.categoriesl.length -1]);


        if(curcat == this.state.categoriesl[this.state.categoriesl.length -1].id){
            this.gotocat(this.state.categoriesl[0].id,this.state.categoriesl[0].name );
            return;
        }

        this.state.categoriesl.forEach(function (e) {
           if(e.id == curcat){

              if(e.count == 0 || e.id == "3657"){
                 this.setState({
                     activecat: e.id,
                 })
                  this.nextcata();
              }

               var y=0;
               this.state.categoriesl.forEach(function (e) {
                   if(x+1 == y){
                       var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
                       var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

                       var authorizationBasic = window.btoa(uname + ':' + pass);
                       var config = {
                           "headers": {
                               "Authorization": "Basic " + authorizationBasic
                           }
                       };

                       var self = this;
                       var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?category="+ e.id +"&per_page=99&status=publish";
                       self.setState({
                           activecat: e.id,
                       });
                    //   console.log(url);


                       axios.get(url, config).then(data1=> {
                           var prod = data1.data;
                           prod.forEach(function (e) {
                 //              console.log("e");
                               e.sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title4");
                               e.sale = e.sale.value;
                               e.sale_percentage = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title10");
                               e.sale_percentage = e.sale_percentage.value;
                               e.off = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title5");
                               e.off = e.off.value;
                               e.uvadzacia_akcia = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title11");
                               e.uvadzacia_akcia = e.uvadzacia_akcia.value;
                               e.body = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title12");
                               e.body = e.body.value;
                               e.exclusive = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title13");
                               e.exclusive = e.exclusive.value;
                               //e.featured_src = "";

                               if(Object.keys(e.images).length > 0) {
                                   e.featured_src = e.images[0].src;
                               }
                               else{
                                   e.featured_src = "";
                               }

                               e.title = e.name;
                               e.trvanlivost= this.findArrayElementByTitle(e.meta_data, "custom_text_field_title22");
                               e.custom_field = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title");
                               e.custom_field = e.custom_field.value;
                               e.subtitle = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title2");
                               e.subtitle = e.subtitle.value;
                               e.extra_sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title25");
                               if(e.extra_sale != undefined) {
                                   e.extra_sale = e.extra_sale.value;
                               }

                           }.bind(this));
                           self.setState({data: data1.data, activecat: e.id, catname: e.name, allowedscroll: true});
                         //  console.log(data1);
                       });

                   }
                    y++;
               }.bind(this));
           }
           x++;

        }.bind(this));
    }



    componentDidMount(){
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();
        window.scrollTo(0, 0);

        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };

        var self = this;
        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products/categories?per_page=99&status=publish";

        axios.get(url, config).then(data1=> {
            var filterdatas = data1.data.filter(item => item.id !== 3657);
            var arko = new Array();

            data1.data = filterdatas;



            self.setState({
                cats: data1,
            })
        });

            if((window.pageYOffset || document.documentElement.scrollTop ) < 600){
            if(window.innerWidth < 480){
                this.setState({
                    isMobile: true,
                })
            }
        }

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 480
            });
        }, false);

        window.addEventListener('scroll', (view) => {



            if(((window.pageYOffset || document.documentElement.scrollTop ) < 600) && window.innerWidth > 480 ){
                if(this.state.menu == false && this.state.handler == false) {
                    this.setState({
                        menu: true,
                        isMobile: true,
                        chliev: false

                    })
                }
            }else{
                if(this.state.menu == true && this.state.handler == false) {

                    this.setState({
                        menu: false,
                        isMobile: false,
                        chliev: true

                    })
                }
            }
        });







        var url2 = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products/categories?hide_empty=true&per_page=40";
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };

        var userko;

        axios.get(url2, config).then(data=> {


            var filterdatas = data.data.filter(item => item.id !== 3657);
            var arko = new Array();

            data.data = filterdatas;

            if(this.state.activecat == "") {
                this.setState({categoriesl: data.data, catname: data.data[0].name, firstcat: data.data[0].id});
            //    console.log(data);
                var self = this;

                var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?category=" + data.data[0].id + "&per_page=99&status=publish";
            }
            else{
                this.setState({categoriesl: data.data});

                var self = this;

                var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?category=" + this.state.activecat + "&per_page=99&status=publish";
            }
            //console.log(url);
            axios.get(url, config).then(data1=> {

                var filterdatas = data1.data.filter(item => item.id !== 3657);
                var arko = new Array();

                data1.data = filterdatas;

            //    console.log(data1);

                var prod = data1.data;

                prod.forEach(function (e) {

                    e.sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title4");
                    e.sale = e.sale.value;
                    e.sale_percentage = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title10");
                    e.sale_percentage = e.sale_percentage.value;
                    e.off = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title5");
                    e.off = e.off.value;
                    e.uvadzacia_akcia = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title11");
                    e.uvadzacia_akcia = e.uvadzacia_akcia.value;
                    e.body = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title12");
                    e.body = e.body.value;
                    e.exclusive = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title13");
                    e.exclusive = e.exclusive.value;
                    //e.featured_src = "";

                    if(Object.keys(e.images).length > 0) {
                        e.featured_src = e.images[0].src;
                    }
                    else{
                        e.featured_src = "";
                    }

                    e.trvanlivost= this.findArrayElementByTitle(e.meta_data, "custom_text_field_title22");
                    e.title = e.name;
                    e.custom_field = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title");
                    e.custom_field = e.custom_field.value;
                    e.subtitle = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title2");
                    e.subtitle = e.subtitle.value;
                    e.extra_sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title25");
                    if(e.extra_sale != undefined) {
                        e.extra_sale = e.extra_sale.value;
                    }

                }.bind(this));


              
                    self.setState({data: data1.data});

               // console.log(data1);
            });

        });



        if(this.findGetParameter("cat") != null){
            this.setState({
               categ: this.findGetParameter("cat"),
            });
        }


        var oznam = localStorage.getItem("oznam");

        if(oznam != null){
            
            this.setState({
                oznam2: false,
            });
        }


        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {

            //    console.log(res.data[1]['acf']['prvy'])
                this.setState({jsonAcf : res.data,
                    mainImage: res.data[1]['acf']['obrazok'],
                    mainText: res.data[1]['acf']['nadpis'],
                    promo: res.data[1]['acf']['promo'],
                    shop: res.data[1]['acf']['nadpis_obchod'],
                    odporucane: res.data[1]['acf']['prvy'],
                    oznam: res.data[1]['acf']['oznam'],
                    produkty: res.data[1]['acf']['cartchange'],
                });

                var prods = localStorage.getItem("cartchange");

                if(prods != null){


                    if(prods != res.data[1]['acf']['cartchange']){
                        localStorage.removeItem("cartchange");
                        localStorage.removeItem("cart");
                        localStorage.removeItem("products");
                        localStorage.setItem("cartchange", res.data[1]['acf']['cartchange']);
                    }
                }
                // else{
                //     localStorage.removeItem("cartchange");
                //     localStorage.removeItem("cart");
                //     localStorage.removeItem("products");
                //     localStorage.setItem("cartchange", res.data[1]['acf']['cartchange']);
                //
                // }

            });




        if(localStorage.getItem("products")!=null){
           let products1 = JSON.parse(localStorage.getItem("products"));

           this.setState({
              products: products1,
           });
        }




        let arrayko = new Array();



    }

    showstate(cats){

    }

    componentWillUnmount(){

        activevat = this.state.activecat;
        catname = this.state.catname;

        state = this.state;
    }

    cancelnot(){
        this.setState({
          oznam2: false,
        })

        localStorage.setItem("oznam", false);
    }

    showmenu = () => {

        this.setState({
            menu: true,
        })
    }

    findArrayElementByTitle(array, key) {
     //   alert("1");

        return array.find((element) => {


            if(element.key == key){
             //   console.log(element.value)
                return element;
            }
        })
    }

    render() {
        if( this.state.odporucane == "" || this.state.cats == "") {
            return (
                <div className="homepage">

                    <section>
                        <div className="mainImage ctn" style={{backgroundImage: `url(${mainimg})`, backgroundSize: 'contain'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section id="promo">
                        <div className="container-fluid">

                            <div className="container">
                                <div className="row">

                                    {
                                        this.state.promo.map((data, index) =>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img src={data.ikonka}/>
                                                    </div>
                                                    <div className="col-10">
                                                        { index == 0 ?
                                                            <a href="https://www.facebook.com/Sladucke.Slovenske.Ovocie/ " target="_blank">
                                                                <h4>{data.nadpis}</h4>
                                                                <p>{data.podnadpis}</p>
                                                            </a>
                                                            : null
                                                        }
                                                        { index == 1 ?
                                                            <Link to="/plan-rozvozu">
                                                                <h4>{data.nadpis}</h4>
                                                                <p>{data.podnadpis}</p>
                                                            </Link>
                                                            : null
                                                        }
                                                        { index == 2 ?
                                                            <Link to="/faq">
                                                                <h4>{data.nadpis}</h4>
                                                                <p>{data.podnadpis}</p>
                                                            </Link>
                                                            : null
                                                        }
                                                        { index == 3 ?
                                                            <Link to="/o-nas">
                                                                <h4>{data.nadpis}</h4>
                                                                <p>{data.podnadpis}</p>
                                                            </Link>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="shop">

                        <div className="container">
                            <div className="row ods">
                                <div className="col-md-12">

                                    <div className="loader">

                                        <Loader
                                            type="Oval"
                                            color="#6A9D60"
                                            height="100"
                                            width="100"
                                        />
                                    </div>
                                    <br/>

                                </div>
                            </div>
                            <div className="row">
                                {
                                    // this.state.products(data =>
                                    //     <div className="col-md-3">
                                    //
                                    //     </div>
                                    // )
                                }
                            </div>
                        </div>
                    </section>
                </div>

            );
        }
        else {
            return (
                <div className="homepage">
                    {
                                            this.state.oznam2 && this.state.oznam != "" ?
                                            <div className="oznam">
                                                <p>{this.state.oznam}</p>

                                                <a className="cancelnot" onClick={this.cancelnot}>X</a>
                                            </div>
                                                : null
                                        }

                    <a onClick={this.showw}><div className={` ${!this.state.handler ? "noshower" : "shower"}`}>
                        <img src={BackBtn} />
                        <span>Kategórie</span>
                    </div>
                    </a>

                    {this.state.activecat != "" ?

                    <a className={'backbtn chlievik ' + (this.state.chliev ? "showchliev" : "noshowchliev")} onClick={this.backtoall} ><img src={BackBtn}/></a>
                        :  null }

                    <div className={`${!this.state.menu ? 'bord-nav showit' : 'bord-nav noshowit'} ${this.state.isMobile ? "mobile" : null }`}>
                        <h6 className="cats">Kategórie</h6>
                        <a className="closex" onClick={this.cancel}>X</a>
                        <a className="closex2" onClick={this.chlievik}>X</a>
                        {this.state.categoriesl != "" && Array.isArray(this.state.categoriesl)?
                        <ul className="categy">
                            {
                                this.state.categoriesl.sort((a, b) => parseInt(a.description) - parseInt(b.description)).map((data,description) => {
                                    if(data.parent == 0){
                                        return(
                                            <li>

                                                <a onClick={() => this.gotocat(data.id, data.name)}
                                                   href={'#' + this.slugify(data.name)}>{data.name}</a>

                                            </li>
                                        )
                                    }


                                    }
                                )}
                        </ul>
                            :null}

                    </div>

                    {this.state.activecat == '' ?
                        <div>
                                 <section>
                                     <div className="mainImage ctn" style={{backgroundImage: `url(${mainimg})`, backgroundSize: 'contain'}}>
                                         <div className="container">
                                             <div className="row">
                                                 <div className="col-md-12">

                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </section>

                                 <section id="promo">
                                     <div className="container-fluid">

                                         <div className="container">
                                             <div className="row">

                                                 {
                                                     this.state.promo.map((data, index) =>
                                                        <div className="col-md-3">
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <img src={data.ikonka}/>
                                                                </div>
                                                                <div className="col-10">
                                                                    { index == 0 ?
                                                                        <a href="https://www.facebook.com/Sladucke.Slovenske.Ovocie/ " target="_blank">
                                                                            <h4>{data.nadpis}</h4>
                                                                            <p>{data.podnadpis}</p>
                                                                        </a>
                                                                        : null
                                                                    }
                                                                    { index == 1 ?
                                                                        <Link to="/plan-rozvozu">
                                                                            <h4>{data.nadpis}</h4>
                                                                            <p>{data.podnadpis}</p>
                                                                        </Link>
                                                                        : null
                                                                    }
                                                                    { index == 2 ?
                                                                        <Link to="/faq">
                                                                            <h4>{data.nadpis}</h4>
                                                                            <p>{data.podnadpis}</p>
                                                                        </Link>
                                                                        : null
                                                                    }
                                                                    { index == 3 ?
                                                                        <Link to="/o-nas">
                                                                            <h4>{data.nadpis}</h4>
                                                                            <p>{data.podnadpis}</p>
                                                                        </Link>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                             </div>
                                         </div>
                                     </div>
                                 </section>
                        </div> : null }
                    {this.state.activecat == "" ?
                    <section id="related">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">

                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <br/><br/>
                                    <h4 id="catname" className="headline">KATEGÓRIE</h4>
                                    <br/><br/>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">

                                {this.state.cats.data.map((data) => {

                                    return data.count > 0 && data.parent == 0 && data.id != 3657  ?

                                    <div className="col-md-3 col-6 prododsbot">

                                        {data.image != null ?
                                            <a onClick={()=>this.gotocat(data.id,data.name)} >
                                                <div className="prodbox">
                                                <div className="product" style={{backgroundImage: `url(${data.image.src})`}}></div>
                                                    <h6 class="prodName">{data.name}</h6><br/>
                                                </div>
                                            </a>
                                            : null }
                                    </div>

                                : null }
                                )}


                            </div>



                            <div className="row">
                                <div className="col-md-12">

                                    <h4 className="headline">ODPORÚČANÉ PRODUKTY</h4>
                                    <br/><br/>
                                </div>
                                {
                                    this.state.odporucane.map((data) =>

                                        <div className="col-md-3 col-6 prododsbot">
                                            <RelatedProduct handleShow={this.handleShow}  id={data.id} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <Modal show={this.state.show} onHide={this.handleClose}
                               size="lg"
                               aria-labelledby="contained-modal-title-vcenter"
                               centered>
                            <Modal.Header closeButton>

                            </Modal.Header>
                            <Modal.Body>

                                <ProductModal url={this.state.sllug} recount={this.props.recount}/>

                            </Modal.Body>

                        </Modal>


                    </section>
                        :null}
                    {this.state.activecat != "" && this.state.data.length > 0 && this.state.loadedcat == 0 && !(this.getvisibility(this.state.activecat) == "both" && this.state.adult == false) ?






                                <div className="container ods">

                                <div className="row">
                                    <div className="col-md-12">
                                        <br/>

                                        <h4 id="catname" className="headline">{this.state.catname}</h4>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                        <div className="row">


                                            {this.state.data.sort((a, b) => a.menu_order - b.menu_order).map(data =>


                                                <div className="col-md-3 col-6 prododsbot">

                                                    <a onClick={()=>this.handleShow(data.slug)} >
                                                        <div className="prodbox">


                                                            <div className="product"
                                                                 style={{backgroundImage: `url(${data.featured_src})`}}></div>

                                                            {(() => {
                                                                if (data.extra_sale == 1) {
                                                                    return (

                                                                        <div className="sale">EXTRA AKCIA {data.sale_percentage}</div>
                                                                    )
                                                                } else if (data.sale == 1) {
                                                                    return (

                                                                        <div className="sale">AKCIA {data.sale_percentage}</div>

                                                                    )}
                                                            })()}

                                                            {data.off == 1 ?
                                                                <div className="vypredaj">VÝPREDAJ</div>
                                                                : null
                                                            }
                                                            {data.uvadzacia_akcia == 1 ?
                                                                <div className="vypredaj">UVÁDZACIA AKCIA</div>
                                                                : null
                                                            }
                                                            {data.body != "" ?
                                                                <div className="vypredaj bodyy">ZÍSKAJTE EXTRA
                                                                    BODY: {data.body} </div>
                                                                : null
                                                            }

                                                            {data.exclusive == 1 ?
                                                                <div className="vypredaj">EXKLUZÍVNE U NÁS</div>
                                                                : null
                                                            }
                                                            {data.trvanlivost != undefined && data.trvanlivost.value != "" ?
                                                                <div className="sale">{data.trvanlivost.value}</div>
                                                                : null
                                                            }
                                                            {data.limited == 1 ?
                                                                <div className="vypredaj">LIMITOVANÉ!</div>
                                                                : null
                                                            }
                                                            <div className="infos">
                                                                <h6 className="prodName">{data.title}</h6>
                                                                <p className="price">{data.custom_field}</p>
                                                                <p className="subtitle">{data.subtitle}</p>

                                                            </div>

                                                        </div>

                                                    </a>
                                                    {this.scrolltoel()}

                                                </div>
                                            )}


                                        </div>


                                    {this.scrolltoel()}

                                    <div className="catbanner">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 leftlll">

                                                    <a className="toleftik" onClick={this.backcat}> <div className="ikonkaaa"><img src={Back}/> </div> </a> <span className="catespan">{this.state.catname}</span> <a className="nextcatik" onClick={this.nextcata}> <div className="ikonkaaa"> <img src={Next}  /></div> </a>
                                                </div>

                                                <div className="col-md-12 dnone">
                                                    <div className="row categy">
                                                        {
                                                            this.state.categoriesl.sort((a, b) => parseInt(a.description) - parseInt(b.description)).map((data,description) => {
                                                                    if(data.parent == 0){
                                                                        return(
                                                                            <div className="col-md-4">

                                                                                <a onClick={() => this.gotocat(data.id, data.name)}
                                                                                   href={'#' + this.slugify(data.name)}>{data.name}</a>

                                                                            </div>
                                                                        )
                                                                    }


                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={this.state.show} onHide={this.handleClose}
                                           size="lg"
                                           aria-labelledby="contained-modal-title-vcenter"
                                           centered>
                                        <Modal.Header closeButton>

                                        </Modal.Header>
                                        <Modal.Body>

                                            <ProductModal url={this.state.sllug} recount={this.props.recount}/>

                                        </Modal.Body>

                                    </Modal>


                                </div>

                        :

                        <div className="container">
                            <div className="row potvrditvek">
                                <div className="col-md-12">
                                    {this.state.activecat != "" && this.state.data.length > 0 && this.state.loadedcat == 0 && (this.getvisibility(this.state.activecat) == "both" && this.state.adult == false) ?
                                        <div>
                                    <p>Na zobrazenie tejto kategórie musíte potvrdiť, že máte 18 rokov</p>
                                    <a onClick={this.setAdult} className="btn btn-primary">Mám 18 rokov</a>
                                    <a className="btn btn-primary" onClick={this.backtoall}>Späť na kategórie</a>
                                        </div>
                                        : null }
                                </div>
                            </div>
                        </div>
                            }

                    {this.state.loadedcat != 0 ?  <div className="loader">

                        <Loader
                            type="Oval"
                            color="#6A9D60"
                            height="100"
                            width="100"
                        />
                    </div> : null}

                    {this.state.data.length == 0 && this.state.activecat != "" ?
                        <div className="loader">
                            <Loader
                                type="Oval"
                                color="#6A9D60"
                                height="100"
                                width="100"
                            />
                            <br/>
                        </div>
                        : null}



                    {this.scrolltoel()}

                </div>
            )
        }

     }
}

let state2 = "";
var scrollToElement = require('scroll-to-element');
class Products extends Component{


    constructor(props) {
        super(props);
        if(state2=="") {
            this.state = {
                categories: new Array(),
                all: [],
                trigger: 0,
                menu: false,
                handler: false,
                isMobile: false,
                chliev: false,
                stock: "",
                categ: "",
                moved: false,
                lopping: 0,

            };
        }
        else{
            this.state = state2;
        }
        this.cancel = this.cancel.bind(this);
        this.showw = this.showw.bind(this);
        this.chlievik = this.chlievik.bind(this);
        this.moveit = this.moveit.bind(this);
        this.scrolltoel = this.scrolltoel.bind(this);


    }


    moveit(){
        var countik = this.state.categories.length - 1;


        if(this.state.categ!= null && countik == this.state.lopping ) {

            let el = '#' + this.state.categ;

            if( document.getElementById(this.state.categ) != null) {

                scrollToElement(el);
                this.setState({
                    moved: true,
                })
            }
        }
    }





    getStockCount = (id) => {
        this.state.stock.forEach(function (e) {

            if(e.id_product == id){

                return e.stock;
            }
        });

    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        var items = window.location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    componentWillUnmount(){
        state2 = this.state;
    }

        componentDidMount(){


        if(this.findGetParameter("cat")!=null){
            this.setState({
                categ: this.findGetParameter("cat"),
            })
        }


            axios.get('https://notifysystem.sladuckeovocie.sk/stock/react')
                .then(res=>{

                    this.setState({
                        stock: res.data.data,
                    });
                });

            if((window.pageYOffset || document.documentElement.scrollTop ) < 600){
                if(window.innerWidth < 480){
                    this.setState({
                        isMobile: true,
                    })
                }
            }

            window.addEventListener('resize', () => {
                this.setState({
                    isMobile: window.innerWidth < 480
                });
            }, false);

            window.addEventListener('scroll', (view) => {



                if(((window.pageYOffset || document.documentElement.scrollTop ) < 600) && window.innerWidth > 480 ){
                    if(this.state.menu == false && this.state.handler == false) {
                       this.setState({
                           menu: true,
                           isMobile: true,
                           chliev: false

                       })
                    }
                }else{
                    if(this.state.menu == true && this.state.handler == false) {

                        this.setState({
                            menu: false,
                            isMobile: false,
                            chliev: true

                        })
                    }
                }
            });


            let arrayko = new Array();

            wooCommerce.get('/products/categories', function(err, data1, res){





                this.setState({all: data1.product_categories});

                this.state.all.map(item1 => {
                    let name1 = item1.name;
                    let url = '/products?filter[category]=' + item1.slug;
                    var description = item1.description;


                    wooCommerce.get(url, function(err, data2, res){

                        var name = name1;
                        var desc = description;
                        var products = data2.products;

                        if(products.length > 0) {

                            arrayko.push({
                                name,description, products
                            });

                            this.setState({
                                categories: arrayko,
                            });
                        }

                    }.bind(this));


                });



            }.bind(this));






        }

        showstat(stat){

            // this.setState({
            //     trigger: 1,
            // });
        }

    not(id){
        this.props.not(id);
    }
    componentWillUnmount() {
        // Remember state for the next mount

    }




     slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }
    showw(){
        this.setState({
            menu: false,
            handler: false,
        })
    }
    cancel(){

        this.setState({
            menu: true,
            handler: true,
        })
    }
    chlievik(){
        this.setState({
            menu: !this.state.menu,
        })
    }
    render() {
        if(this.state.categories.length == 0){
            return(
                <div className="loader">
                    <Loader
                        type="Oval"
                        color="#6A9D60"
                        height="100"
                        width="100"
                    />
                    <br/>
                </div>

            );
        }
        else if(this.state.categories.length){
        return(
            <div className="kategorie ods">
                <a onClick={this.showw}><div className={` ${!this.state.handler ? "noshower" : "shower"}`}>
                    <img src={BackBtn} />
                    <span>Kategórie</span>
                </div>
                </a>

                    <a className={'backbtn chlievik ' + (this.state.chliev ? "showchliev" : "noshowchliev")} onClick={this.chlievik} ><img src={BackBtn}/><span>Filter</span></a>

                <div className={`${!this.state.menu ? 'bord-nav showit' : 'bord-nav noshowit'} ${this.state.isMobile ? "mobile" : null }`}>
                    <h6 className="cats">Kategórie</h6>
                    <a className="closex" onClick={this.cancel}>X</a>
                    <a className="closex2" onClick={this.chlievik}>X</a>
                    <br/>
                    <ul>
                        {
                            this.state.categories.sort((a, b) => parseInt(a.description) - parseInt(b.description)).map((data,description) =>



                                <li>

                                    <a href={'#'+this.slugify(data.name)}>{data.name}</a>

                                </li>
                        )}
                    </ul>

                </div>
                {
                    this.state.categories.sort((a, b) => parseInt(a.description) - parseInt(b.description)).map((data,description) =>
                        <div className="container">
                            <div className="row">
                                { this.state.moved == false ?
                                    this.moveit() : null
                                }



                                <div className="col-md-12">

                                    <h4 id={ this.slugify(data.name) } className="headline">{data.name}</h4>
                                </div>
                            </div>

                            <div className="row">
                                {

                                    data.products.sort((a, b) => a.menu_order - b.menu_order).map(data =>

                                        <div className="col-md-3 col-6 prododsbot">

                                            <Link to={'/produkt/' + data.attributes[0].slug} >
                                                <div className="prodbox">
                                                    {(() => {
                                                        if (data.extra_sale == 1) {
                                                            return (

                                                                <div className="sale">EXTRA AKCIA {data.sale_percentage}</div>
                                                            )
                                                        } else if (data.sale == 1) {
                                                            return (

                                                                <div className="sale">AKCIA {data.sale_percentage}</div>

                                                            )}
                                                    })()}
                                                    {data.off == 1 ?
                                                        <div className="vypredaj">VÝPREDAJ</div>
                                                        : null
                                                    }
                                                    {data.uvadzacia_akcia == 1 ?
                                                        <div className="vypredaj">UVÁDZACIA AKCIA</div>
                                                        : null
                                                    }
                                                    {data.body != "" ?
                                                        <div className="vypredaj bodyy">ZÍSKAJTE EXTRA BODY: {data.body} </div>
                                                        : null
                                                    }
                                                    {data.exclusive == 1 ?
                                                        <div className="vypredaj">EXKLUZÍVNE U NÁS</div>
                                                        : null
                                                    }
                                                    {data.limited == 1 ?
                                                        <div className="vypredaj">LIMITOVANÉ!</div>
                                                        : null
                                                    }
                                                    <div className="product" style={{backgroundImage: `url(${data.featured_src})`}}></div>
                                                    <div className="infos">
                                                        <h6 className="prodName">{data.title}</h6>
                                                        <p className="price">{data.custom_field}</p>
                                                        <p className="subtitle">{data.subtitle}</p>

                                                    </div>
                                                </div>

                                            </Link>


                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }

            </div>
        );
        }
        else{
            return null;
        }

    }


}

class RelatedProduct extends Component{
    constructor(props){
        super(props);
        this.state = {
            product: "",

        }
    }

     componentDidMount(){
        var id = this.props.id;
        this.setState({
            adult: this.props.adult,
        })

         var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
         var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

         var authorizationBasic = window.btoa(uname + ':' + pass);
         var config = {
             "headers": {
                 "Authorization": "Basic " + authorizationBasic
             }
         };

         var self = this;
         var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products/"+ id;

         axios.get(url, config).then(data1=> {
             console.log(data1);
             var e = data1.data;


                 e.sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title4");
                 e.sale = e.sale.value;
                 e.sale_percentage = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title10");
                 e.sale_percentage = e.sale_percentage.value;
                 e.off = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title5");
                 e.off = e.off.value;
                 e.uvadzacia_akcia = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title11");
                 e.uvadzacia_akcia = e.uvadzacia_akcia.value;
                 e.body = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title12");
                 e.body = e.body.value;
                 e.exclusive = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title13");
                 e.exclusive = e.exclusive.value;
                 e.trvanlivost= this.findArrayElementByTitle(e.meta_data, "custom_text_field_title22");

                 //e.featured_src = "";

                 if(Object.keys(e.images).length > 0) {
                     e.featured_src = e.images[0].src;
                 }
                 else{
                     e.featured_src = "";
                 }

                 e.title = e.name;
                 e.custom_field = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title");
                 e.custom_field = e.custom_field.value;
                 e.subtitle = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title2");
                 e.subtitle = e.subtitle.value;
             e.extra_sale = this.findArrayElementByTitle(e.meta_data, "custom_text_field_title25");
             if(e.extra_sale != undefined) {
                 e.extra_sale = e.extra_sale.value;
             }

             this.setState({
                 product: e,
             });

             });



     }
    findArrayElementByTitle(array, key) {
        //   alert("1");

        return array.find((element) => {


            if(element.key == key){
                //   console.log(element.value)
                return element;
            }
        })
    }


    render() {
        if (this.state.product != "") {
            return (
                    <a onClick={()=>this.props.handleShow(this.state.product.slug)} >

                        <div className="prodbox">

                            <div className="product"
                                 style={{backgroundImage: `url(${this.state.product.featured_src})`}}></div>
                            {(() => {
                                if (this.state.product.extra_sale == 1) {
                                    return (

                                        <div className="sale">EXTRA AKCIA {this.state.product.sale_percentage}</div>


                                    )
                                } else if (this.state.product.sale == 1) {
                                    return (
                                        <div className="sale">AKCIA {this.state.product.sale_percentage}</div>
                                    )}
                            })()}
                            {this.state.product.off == 1 ?
                                <div className="vypredaj">VÝPREDAJ</div>
                                : null
                            }
                            {this.state.product.uvadzacia_akcia == 1 ?
                                <div className="vypredaj">UVÁDZACIA AKCIA</div>
                                : null
                            }
                            {this.state.product.body != "" ?
                                <div className="vypredaj bodyy">ZÍSKAJTE EXTRA BODY: {this.state.product.body} </div>
                                : null
                            }
                            {this.state.product.exclusive == 1 ?
                                <div className="vypredaj">EXKLUZÍVNE U NÁS</div>
                                : null
                            }
                            {this.state.product.limited == 1 ?
                                <div className="vypredaj">LIMITOVANÉ!</div>
                                : null
                            }
                            <div className="infos">
                                <h6 className="prodName">{this.state.product.title}</h6>

                                <p className="price">{this.state.product.custom_field}</p>
                                <p className="subtitle">{this.state.product.subtitle}</p>
                            </div>
                        </div>

                    </a>


            );
        }
        else{
            return(
                <div></div>
            );
        }
    }


}

export default Homepage;