import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Icon from "./iconmonstr-magnifier-1.svg";
import HamburgerMenu from 'react-hamburger-menu';
import $ from 'jquery';
import axios from "axios/index";

var WooCommerce = require('woocommerce');

var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});
var stringSimilarity = require('string-similarity');
class Header extends Component{
    constructor(props){
        super(props);

        this.state = {
            incart: 0,
            price: 0,
            search: false,
            products: [],
            name:"",
            searched: [],
            open: false,

        }
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        this.setState({
            open: !this.state.open,
        })
    }


    handleChange(event){
        this.setState({
            name: event.target.value,
        })
    }
    handleSubmit(event){
        event.preventDefault();
        var array = new Array();




        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };

        var self = this;
        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?search="+ this.state.name + "&status=publish";
        console.log(url);

        axios.get(url, config).then(data1=> {
            data1.data.forEach(function (e) {
                array.push(e);
            }.bind(this));


            self.setState({
                searched: array,
            })
        });


    }
    slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    componentDidMount() {
        this.props.onRef(this)
        var count = localStorage.getItem("cart");

        if (count != null) {
            count = JSON.parse(count);

            var pric = 0;
            count.forEach(function (e) {
               pric = pric + parseFloat(e[3].PRICE);

            }.bind(this));

            count = count.length;

            this.setState({
                incart: count,
                price: Math.round(pric*100)/100,
            });




        }

        $('.navbar a').on('click', function(){

            $(".navbar-toggle").click(); //bootstrap 3.x by Richard
            $(".navbar-collapse").removeClass("show");
            this.forceUpdate({});
        });

        if(localStorage.getItem("products")!=null){
            let products1 = JSON.parse(localStorage.getItem("products"));

            this.setState({
                products: products1,
            });
        }
        else {
            // wooCommerce.get('/products', function (err, data, res) {
            //
            //
            //     this.setState({
            //         products: data.products,
            //     });
            //     localStorage.setItem("products", JSON.stringify(data.products));
            // }.bind(this));

        }


    }

    search = () => {
        if(this.state.search == false) {
            this.setState({
                search: true,
            })
        }
        else{
            this.setState({
                search: false,
            })
        }
    }
    close = () => {
        this.state({
            search: false,
        })
    }

    recount(){


        var count = localStorage.getItem("cart");
        if(count != null) {
            count = JSON.parse(count);

            let pric = 0;
            count.forEach(function (e) {
                pric = pric + parseFloat(e[3].PRICE);

            }.bind(this));
            count = count.length;
            this.setState({
                incart: count,
                price: Math.round(pric*100)/100,
            });
        }
    }


    render() {
        return(
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                    <a className="navbar-brand" href="/">Sladučké ovocie</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <HamburgerMenu
                            isOpen={this.state.open}
                            menuClicked={this.handleClick.bind(this)}
                            width={25}
                            height={15}
                            strokeWidth={1}
                            rotate={0}
                            color='black'
                            borderRadius={0}
                            animationDuration={0.5}
                        />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Obchod</Link>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/o-nas">O nás</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/plan-rozvozu">Plán rozvozu</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/faq">Často kladené otázky</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/kontakt">Kontakt</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/kontaktny-formular">Formulár</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/moj-ucet">Môj účet</Link>
                            </li>
                            <li className="nav-item cenalign ondeskt">
                                <a onClick={this.search}><img src={Icon} /></a>
                            </li>


                        </ul>

                    </div>
                        <a className="onmobile" onClick={this.search}><img src={Icon} /></a>
                    </div>

                </nav>
                {this.state.search == true ?
                    <div className="search">
                        <br/><br/>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <a className="closeitsearch" onClick={this.search}>X</a>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label for="meno" /> Zadajte, čo hľadáte <br/>
                                                <input type='text' className="form-control" name="meno" placeholder="Názov produktu" onChange={this.handleChange} autofocus="true"/><br/>
                                                <input type="submit" className="btn btn-primary"/>
                                        </div>
                                    </form>
                                    {this.state.searched.length > 0 ?
                                        <div>

                                            {this.state.searched.map(data=>
                                                <li><Link onClick={this.close} to={"/produkt/"+data.slug}>{data.name}</Link></li>
                                            )}

                                        </div> : null

                                    }
                                </div>
                            </div>
                        </div>
                        <br/>

                    </div>:null
                }
            </div>

        );
    }
}
export default Header;