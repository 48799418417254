import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';


var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,

    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});



class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meno: "",
            cislo: "",
            sprava: "",
            email: "",
        };

        this.handleSprava = this.handleSprava.bind(this);
        this.validate = this.validate.bind(this);
        this.handleCislo = this.handleCislo.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleMeno = this.handleMeno.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    handleMeno(event) {
        this.setState({meno: event.target.value});
    }
    handleEmail(event) {
        this.setState({email: event.target.value});
    }
    handleCislo(event) {
        this.setState({cislo: event.target.value});
    }
    handleSprava(event) {
        this.setState({sprava: event.target.value});
    }
    validate(event) {
        event.preventDefault();

        var datas = {
            meno: this.state.meno,
            email: this.state.email,
            cislo: this.state.cislo,
            sprava: this.state.sprava,
        }

        datas = JSON.stringify(datas);

        axios.post('https://notifysystem.sladuckeovocie.sk/message',datas,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } )
            .then(res => {

                NotificationManager.info("Vaša správa bola úspešné odoslaná", "Ďakujeme za kontaktovanie", 1000);
            });
        event.preventDefault();
    }

    render() {

            return (

                <div className="contact">
                    <NotificationContainer/>
                    <div className="container-fluid nopad">
                        <iframe className="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2633.1456341023595!2d18.39815845135612!3d48.7026973195086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714c60128ed2c57%3A0x2475663be5d567fa!2sObrancov+mieru%2C+972+23+Doln%C3%A9+Vestenice!5e0!3m2!1ssk!2ssk!4v1554472156824!5m2!1ssk!2ssk"></iframe>
                    </div>
                     <div className="container">
                        <div className="row ods">
                            <div className="col-md-12">
                                <h1 className="kontakt">Kontaktné údaje</h1>
                            </div>
                        </div>
                        <div className="row ods odsbot">
                            <div className="col-md-6 info">
                                <p>Sladučké ovocie s.r.o.</p>
                                <p>IČO: 51029081</p>
                                <p>DIČ: 2120568351</p>
                                <p>IČ DPH: SK21 20 568 351</p>
                                <p>922 23 Dolné Vestenice</p>
                                <p>okres Prievidza</p>
                                <br/>

                                <Link to="/ochrana-sukromia" >Ochrana súkromia</Link><br/>
                                <Link to="/obchodne-podmienky" >Obchodné podmienky</Link><br/>

                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeYX0rwsO5hQew0BmVEWrdLd9JAw1ELj4j-_m9VSHeYkMUZHQ/viewform" target="_blank">Formulár na odstúpenie od zmluvy</a><br/>
                                <Link className="hiditnow" to="/zmena-adresy">Formulár na zmenu adresy</Link><br/>
                                <Link to="/obchodne-podmienky" >Orgány dozoru</Link><br/>




                                <p><a href="mailto: info@sladuckeovocie.sk"> info@sladuckeovocie.sk</a></p>
                                <p><a href="mailto: praca@sladuckeovocie.sk"> praca@sladuckeovocie.sk</a></p>

                                <p>Kontaktujte nás priamo cez <a target="_blank" href="https://www.facebook.com/Sladucke.Slovenske.Ovocie">Facebook</a></p>
                                <p>OSOBNÉ STRETNUTIE LEN PO DOHODE</p>

                                <p>V prípade záujmu o zmenu objednávky, jej doplnenie, reklamáciu alebo akýkoľvek iný úkon je potrebné nás kontaktovať VÝLUČNE PÍSOMNE mailom alebo na Facebooku</p>




                            </div>
                            <div className="col-md-6 cental">
                                <br/><br/><br/><br/>
                                <p className="center">Pred tým ako nám napíšete, si môžete prečítať <Link to="/faq">odpovede na najčastejšie kladené otázky</Link>.</p>
                                <br/>
                                <Link className="btn btn-primary" to={"/kontaktny-formular"}>Kontaktný formulár</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );

    }
}

export default Contact;

