import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Pencil from "../pencil.png";
import Close from "../close.png";




var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});


class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meno: "",
            cislo: "",
            sprava: "",
            email: "",
            id: "",
            logged: false,
            firstname: "",
            lastname: "",
            postcode: "",
            city: "",
            email: "",
            phone: "",
            street: "",
            street_number: "",
            loader: 0,
            registerName: "",
            registerLastname: "",
            registerEmail: "",
            registerNickname: "",
            canaplied: "",
            registerName2: "",
            registerLastname2: "",
            registerEmail2: "",
            registerNickname2: "",
            registerPassword: "",
            registerPassword2: "",
            registerPassword3: "",
            registerPassword4: "",
            success: "",
            user: "",
            orders: false,
            useremail: "",
            sale: "",
            saleapply: "",
            hrefs: new Array(),
            editudaje:false,
            editdodacie:false,

            udajmeno: "",
            udajpriezvisko: "",
            loader1: false,
            udajemail: "",
            udajcislo: "",

            dodaciemeno: "",
            dodaciepriezvisko: "",
            dodacieemail: "",
            dodaciecislo: "",
            dodacieulica: "",
            dodaciecisloulice: "",
            dodaciepsc: "",
            dodaciemesto: "",
            dodaciedom: "",


        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleRegisterName = this.handleRegisterName.bind(this);
        this.handleRegisterLastname = this.handleRegisterLastname.bind(this);
        this.handleRegisterEmail = this.handleRegisterEmail.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleRegisterNickname = this.handleRegisterNickname.bind(this);
        this.handleRegisterPassword = this.handleRegisterPassword.bind(this);
        this.handleRegisterPassword2 = this.handleRegisterPassword2.bind(this);
        this.showEditUdaje = this.showEditUdaje.bind(this);
        this.showEditDodacie = this.showEditDodacie.bind(this);
        this.closeIt = this.closeIt.bind(this);


        this.handleUdajCislo = this.handleUdajCislo.bind(this);
        this.handleUdajMeno = this.handleUdajMeno.bind(this);
        this.handleUdajPriezvisko = this.handleUdajPriezvisko.bind(this);
        this.handleUdajEmail = this.handleUdajEmail.bind(this);

        this.handleDodacieMeno = this.handleDodacieMeno.bind(this);
        this.handleDodaciePriezvisko = this.handleDodaciePriezvisko.bind(this);
        this.handleDodacieEmail = this.handleDodacieEmail.bind(this);
        this.handleDodacieCislo = this.handleDodacieCislo.bind(this);
        this.handleDodacieUlica = this.handleDodacieUlica.bind(this);
        this.handleDodacieCisloUlice = this.handleDodacieCisloUlice.bind(this);
        this.handleDodacieMesto = this.handleDodacieMesto.bind(this);
        this.handleDodaciePsc = this.handleDodaciePsc.bind(this);
        this.handleDodacieDom = this.handleDodacieDom.bind(this);
        this.submitDodacie = this.submitDodacie.bind(this);



    }

    handleDodacieDom = (event) => {
        this.setState({
            dodaciedom: event.target.value,
        })

    }

    submitUdaje = (event) => {
        event.preventDefault();
        this.setState({
            loader1: true,
        });

        var datas = {
          first_name: this.state.udajmeno,
          last_name: this.state.udajpriezvisko,
          email: this.state.udajemail,
            billing: {
              phone: this.state.udajcislo,
            }
        };


        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/customers/" + this.state.id;


        var session_url = url;
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };


        axios.post(session_url, datas, config).then(data => {

            var self = this;

            wooCommerce.get('/customers/'+ this.state.id, function (err, data, res) {

                var datas1 = data;
                datas1 = datas1.customer;
                localStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(datas1));
                var thenum = datas1.billing_address.address_1.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
                var str = datas1.billing_address.address_1.replace(/[0-9]/g, '');


                self.setState({
                    firstname: datas1.billing_address.first_name,
                    lastname: datas1.billing_address.last_name,
                    postcode: datas1.billing_address.postcode,
                    city: datas1.billing_address.city,
                    street: str,
                    street_number: datas1.billing_address.address_2,
                    phone: datas1.billing_address.phone,
                    email: datas1.billing_address.email,
                    user: datas1,
                    logged: 1,

                });







                if(datas1.billing_address.first_name == ""){
                    self.setState({
                        firstname: datas1.first_name,
                        lastname: datas1.last_name,
                    });
                }
                self.setState({
                    loader: 0,
                    loader1: false,
                });
                return;
            });
        });
        this.setState({
            editudaje: false,
        });
    }
    submitDodacie = (event) => {


        event.preventDefault();
        this.setState({
            loader1: true,
        });
        var datas = {
            first_name: this.state.dodaciemeno,
            last_name: this.state.dodaciepriezvisko,
            email: this.state.dodacieemail,
            billing: {
                first_name: this.state.dodaciemeno,
                last_name: this.state.dodaciepriezvisko,
                email: this.state.dodacieemail,
                phone: this.state.dodaciecislo,
                address_1: this.state.dodacieulica,
                address_2: this.state.dodaciedom,
                postcode: this.state.dodaciepsc,
                city: this.state.dodaciemesto,
            },
            shipping: {
                first_name: this.state.dodaciemeno,
                last_name: this.state.dodaciepriezvisko,
                email: this.state.dodacieemail,
                phone: this.state.dodaciecislo,
                address_1: this.state.dodacieulica,
                address_2: this.state.dodaciedom,
                postcode: this.state.dodaciepsc,
                city: this.state.dodaciemesto,
            }



        };

        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/customers/" + this.state.id;


        var session_url = url;
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };


        axios.post(session_url, datas, config).then(data => {
            console.log(data);

            var self = this;

            wooCommerce.get('/customers/'+ this.state.id, function (err, data, res) {

                var datas1 = data
                datas1 = datas1.customer;
                localStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(datas1));
                var thenum = datas1.billing_address.address_1.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
                var str = datas1.billing_address.address_1.replace(/[0-9]/g, '');


                self.setState({
                    firstname: datas1.billing_address.first_name,
                    lastname: datas1.billing_address.last_name,
                    postcode: datas1.billing_address.postcode,
                    city: datas1.billing_address.city,
                    street: str,
                    street_number: datas1.billing_address.address_2,
                    phone: datas1.billing_address.phone,
                    email: datas1.billing_address.email,
                    user: datas1,
                    logged: 1,

                });







                if(datas1.billing_address.first_name == ""){
                    self.setState({
                        firstname: datas1.first_name,
                        lastname: datas1.last_name,
                    });
                }
                self.setState({
                    loader: 0,
                    loader1: false,
                });
                return;
            });



        });
        this.setState({
            editdodacie: false,
        });
    }
    handleDodacieMeno = (event) => {
        this.setState({
            dodaciemeno: event.target.value,
        });
    }
    handleDodaciePriezvisko = (event) => {
        this.setState({
            dodaciepriezvisko: event.target.value,
        });
    }
    handleDodacieEmail = (event) => {
        this.setState({
            dodacieemail: event.target.value,
        });
    }
    handleDodacieCislo = (event) => {
        this.setState({
        dodaciecislo: event.target.value,
        });
    }
    handleDodacieUlica = (event) => {
        this.setState({
            dodacieulica: event.target.value,
        });
    }
    handleDodacieCisloUlice = (event) => {
        this.setState({
            dodaciecisloulica: event.target.value,
        });
    }
    handleDodacieMesto = (event) => {
        this.setState({
            dodaciemesto: event.target.value,
        });
    }
    handleDodaciePsc = (event) => {
        this.setState({
            dodaciepsc: event.target.value,
        });
    }
    handleUdajEmail = (event) =>{
        this.setState({
            udajemail: event.target.value,
        })
    }
    handleUdajPriezvisko = (event) => {
        this.setState({
            udajpriezvisko: event.target.value,
        })
    }
    handleUdajMeno = (event) => {
        this.setState({
            udajmeno: event.target.value,
        })
    }
    handleUdajCislo = (event) => {
        this.setState({
            udajcislo: event.target.value,
        })
    }


    closeIt(){
        this.setState({
            editudaje:false,
            editdodacie:false,
        })
    };


    showEditUdaje(){
        this.setState({
            editudaje:true,
        })
    }
    showEditDodacie(){
        this.setState({
            editdodacie:true,
        })
    }

    handleRegisterName(event){
        this.setState({registerName: event.target.value});
    }
    handleRegisterPassword2(event){
        this.setState({registerPassword4: event.target.value});

        if(this.state.registerPassword != event.target.value){
            this.setState({
                registerPassword3: "Hesla sa nezhodujú",
                registerPassword2: "Hesla sa nezhodujú",
            });
        }
        else{
            this.setState({
                registerPassword3: "",
                registerPassword2: "",
            });
        }
    }
    handleRegisterPassword(event){
        this.setState({registerPassword: event.target.value});
    }
    handleRegisterLastname(event){
        this.setState({registerLastname: event.target.value});
    }
    handleRegisterEmail(event){
        this.setState({registerEmail: event.target.value});
    }
    handleRegisterNickname(event){
        this.setState({registerNickname: event.target.value})
    }
    newreg = () => {
        this.setState({
            success: "",
        })
    }
    handleRegister(event){
        event.preventDefault();


        if(this.state.registerName == ""){
            this.setState({registerName2: "* povinná položka"})
        }
        else{
            this.setState({registerName2: ""})
        }
        if(this.state.registerLastname == ""){
            this.setState({registerLastname2: "* povinná položka"})
        }
        else{
            this.setState({registerLastname2: ""})
        }
        if(this.state.registerPassword == ""){
            this.setState({registerPassword2: "* povinná položka"});
        }
        else{
            this.setState({registerPassword2: ""});
        }
        if(this.state.registerEmail == ""){
            this.setState({registerEmail2: "* povinná položka"})
        }
        else{
            this.setState({registerEmail2: ""})
        }
        if(this.state.registerNickname == ""){
            this.setState({registerNickname2: "* povinná položka"})
        }
        else{
            this.setState({registerNickname2: ""})
        }

        if(this.state.registerName == "" || this.state.registerLastname == "" || this.state.registerEmail == "" || this.state.registerNickname == ""|| this.state.registerPassword == ""){
            return false;
        }
        if(this.state.registerPassword != this.state.registerPassword4){
            return false;
        }

        var data = {
            email: this.state.registerEmail,
            first_name: this.state.registerName,
            last_name: this.state.registerLastname,
            username: this.state.registerNickname,
            password: this.state.registerPassword,
        };

        wooCommerce.post('/customers', data, function(err, data, res) {


            if(res.statusCode == 400){

                this.setState({
                    success: false
                })
            }
            else{
                this.setState({
                    success: true
                })
            }
        }.bind(this));


    }
    orders = () => {
        this.setState({orders: true})
    }

    details = () => {
        this.setState({orders: false})
    }

    handleUsername(event) {
        this.setState({useremail: event.target.value});
    }
    handlePassword(event) {
        this.setState({password: event.target.value});
    }

    logout = () => {
        localStorage.removeItem("user");
        this.setState({
            logged: false,
        });
    }


    componentWillUnmount(){

    }
    componentDidMount(){
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();

        window.scrollTo(0, 0);

        var user = localStorage.getItem("user");
        user = JSON.parse(user);

        if(user != null) {
            if (typeof user.billing_address != 'undefined') {

                this.setState({
                    logged: true,
                });



                var thenum = user.billing_address.address_1.replace(/^\D+/g, ''); // replace all leading non-digits with nothing
                var str = user.billing_address.address_1.replace(/[0-9]/g, '');

                this.setState({

                    id: user.id,
                    firstname: user.billing_address.first_name,
                    lastname: user.billing_address.last_name,
                    postcode: user.billing_address.postcode,
                    city: user.billing_address.city,
                    street: str,
                    street_number: user.billing_address.address_2,
                    phone: user.billing_address.phone,
                    email: user.billing_address.email,
                    user: user,


                    udajmeno: user.first_name,
                    udajpriezvisko: user.last_name,
                    udajemail: user.email,
                    udajcislo: user.billing_address.phone,

                    dodaciemeno: user.billing_address.first_name,
                    dodaciepriezvisko: user.billing_address.last_name,
                    dodacieemail: user.billing_address.email,
                    dodaciecislo: user.billing_address.phone,
                    dodacieulica: str,
                    dodaciedom: user.billing_address.address_2,
                    dodaciepsc: user.billing_address.postcode,
                    dodaciemesto: user.billing_address.city,
                });

                if (user.billing_address.phone != "") {
                    var datas = {
                        cislo: user.billing_address.phone,
                    };


                    datas = JSON.stringify(datas);


                    axios.post('https://notifysystem.sladuckeovocie.sk/api/canapplysale', datas, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                        .then(res => {


                            if (res.data.message == "can") {
                                this.setState({
                                    sale: res.data.value,
                                    saleapply: true,
                                })
                            }
                            else {
                                this.setState({
                                    sale: "",
                                    saleapply: false,
                                })
                            }


                        }).catch(error => {
                        this.setState({
                            sale: "",
                            saleapply: false,
                        })
                    });
                }

                if (user.billing_address.first_name == "") {
                    this.setState({
                        firstname: user.first_name,
                        lastname: user.last_name,

                    });
                }


            }
        }
        else{
            localStorage.removeItem('user');
        }



    }
    componentDidUpdate(){

        if(this.state.logged == 1 && this.state.loader == 1){
            this.setState({
                loader: 0,
            })
        }



        if(this.state.sale == "" && this.state.phone != "" && this.state.canaplied == ""){

            var datas = {
                cislo: this.state.phone,
            };



            datas = JSON.stringify(datas);




            var self = this;

            axios.post('https://notifysystem.sladuckeovocie.sk/api/canapplysale',datas,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            } )
                .then(res => {



                    self.setState({
                        canaplied: "yes",
                    })

                    if(res.data.message == "can") {
                        self.setState({
                            sale: res.data.value,
                            saleapply: true,
                        })
                    }
                    else{
                        self.setState({
                            sale: "",
                            saleapply: false,
                        })
                    }


                });



        }
    }

    handleLogin = (event) => {

        this.setState({
            loader: 1,
        });


        const self = this;
        event.preventDefault();


        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/customers?email=" + this.state.useremail;
        console.log(url);

        var session_url = url;
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };

        var userko;
        axios.get(session_url, config).then(data=> {


            if(data.data.length == 0){

                self.setState({
                    loader: 0,
                    error: "Na tento e-mail neevidujeme konto, môžete si ho vytvoriť po zadaní objednávky.",
                });
                return;
            }

                    console.log(data);

            userko = data.data[0].username;


            var username = userko;


            axios.post('https://www.sladuckeovocie.sk/wp-json/jwt-auth/v1/token', {username: username, password: this.state.password})
                .then(res => {





                    localStorage.setItem("auth_user", res.data);


                    axios.get('https://www.sladuckeovocie.sk/wp-json/wp/v2/users/me', { headers: { Authorization: `Bearer ${res.data.token}` } })
                        .then(res => {

                            wooCommerce.get('/customers/'+ res.data.id, function (err, data, res) {

                                var datas1 = JSON.parse(data);
                                datas1 = datas1.customer;
                                localStorage.setItem("user", JSON.stringify(datas1));
                                var thenum = datas1.billing_address.address_1.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
                                var str = datas1.billing_address.address_1.replace(/[0-9]/g, '');


                                self.setState({
                                    firstname: datas1.billing_address.first_name,
                                    lastname: datas1.billing_address.last_name,
                                    postcode: datas1.billing_address.postcode,
                                    city: datas1.billing_address.city,
                                    street: str,
                                    street_number: datas1.billing_address.address_2,
                                    phone: datas1.billing_address.phone,
                                    email: datas1.billing_address.email,
                                    user: datas1,
                                    logged: 1,

                                });







                                if(datas1.billing_address.first_name == ""){
                                    self.setState({
                                        firstname: datas1.first_name,
                                        lastname: datas1.last_name,
                                    });
                                }
                                self.setState({
                                    loader: 0,
                                });
                                return;
                            });
                        });



                }).catch(error => {

                self.setState({
                    loader: 0,
                    error: "Vaše prihlasovacie údaje nie sú správne",
                });
                return;

            });


            }
        );




            var x=0;





                    return;









    }



    render() {

        return (
            <div className="vop">
                <NotificationContainer />
                <section>
                    <div className="mainImage" style={{  backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text">
                                        Môj účet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    {!this.state.loader ?
                        <div className="container">
                            <div className="row ods odsbot">

                                    {
                                        !this.state.logged ?
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="login ods odsbot">
                                                            <h2 className="nadpis">Prihláste sa</h2>

                                                            <form onSubmit={this.handleLogin}>
                                                                <div className="form-group">
                                                                    <label for="username"/>E-Mail<br/>
                                                                    <input className="form-control" name="username" type="text"
                                                                           onChange={this.handleUsername}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label for="username"/>Heslo<br/>
                                                                    <input className="form-control" name="username" type="password"
                                                                           onChange={this.handlePassword}/>
                                                                </div>
                                                                <input className="btn btn-primary" name="username" value="Prihlásiť sa" type="submit"/>
                                                                <span className="errorinput">&nbsp;{this.state.error}</span>

                                                            </form>
                                                            <br/>
                                                            <a href="/wp-login.php?action=lostpassword" className="btn btn-primary">Zabudol som heslo</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            :
                                            <div className="col-md-12">
                                                <div className="logged">
                                                    {/*<h2 className="nadpis">Vítajte, {this.state.firstname} {this.state.lastname}</h2>*/}
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <ul className="myaccul">
                                                                <li>
                                                                    <a onClick={this.details} >Môj účet</a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={this.orders} >Moje objednávky</a>
                                                                </li>

                                                                <li>
                                                                    <a onClick={this.logout}>Odhlásiť sa</a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-md-9">

                                                    {this.state.orders ?
                                                        <div className="orders">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <Orders/>
                                                                </div>
                                                            </div>
                                                        </div> :


                                                        <div>
                                                            {!this.state.loader1 ?
                                                            <div className="details">

                                                                {this.state.editudaje == false && this.state.editdodacie == false ?
                                                                <div className="row">

                                                                    {/*<div className="col-md-6">*/}

                                                                        {/*<h2 className="nadpis inl"> Moje údaje</h2> <a onClick={this.showEditUdaje}><img className="pencil" src={Pencil}/> </a>*/}
                                                                            {/*<div className="boxacc tothecenter">*/}
                                                                                {/*<p>{this.state.firstname} {this.state.lastname}</p>*/}
                                                                                {/*<p>{this.state.email}</p>*/}
                                                                                {/*<p>{this.state.phone}</p>*/}
                                                                            {/*</div>*/}
                                                                    {/*</div>*/}


                                                                    <div className="col-md-12 odtopik">
                                                                        <h2 className="nadpis inl"> Dodacie údaje</h2> <a onClick={this.showEditDodacie}><img className="pencil" src={Pencil}/> </a>
                                                                        <div className="boxacc">
                                                                            <p>{this.state.firstname} {this.state.lastname}</p>
                                                                            <p>{this.state.email}</p>
                                                                            <p>{this.state.phone}</p>
                                                                            <p>{this.state.street} {this.state.street_number}</p>
                                                                            <p>{this.state.city}</p>
                                                                            <p>{this.state.postcode}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <span><strong> Vernostný program: </strong>
                                                                            { this.state.saleapply == true ?
                                                                                <span>

                                                                                    {this.state.sale} €
                                                                                </span>

                                                                                : <div className="inlinko">

                                                                                    <span>0 €</span>

                                                                                </div>}

                                                                        </span>


                                                                    </div>

                                                                </div>
                                                                    : null }
                                                                {this.state.editudaje == true ?
                                                                    <div>
                                                                        <h2 className="nadpis inl">Zmena údajov</h2> <a onClick={this.closeIt} ><img className="pencil" src={Close}/> </a>
                                                                        <form onSubmit={this.submitUdaje}>
                                                                            <div className="form-group">
                                                                                <label for="name">Meno</label><br/>
                                                                                <input className="form-control" onChange={this.handleUdajMeno} type="text" value={this.state.udajmeno} />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">Priezvisko</label><br/>
                                                                                <input className="form-control" onChange={this.handleUdajPriezvisko} type="text" value={this.state.udajpriezvisko} />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">E-mail</label><br/>
                                                                                <input className="form-control" onChange={this.handleUdajEmail} type="text" value={this.state.udajemail} />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">Tel. číslo</label><br/>
                                                                                <input className="form-control" onChange={this.handleUdajCislo} type="text" value={this.state.udajcislo} />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="submit" className="btn btn-primary" />
                                                                            </div>

                                                                        </form>
                                                                    </div>
                                                                    : null}

                                                                {this.state.editdodacie == true ?
                                                                    <div>
                                                                        <h2 className="nadpis inl">Zmena dodacích údajov</h2> <a onClick={this.closeIt} ><img className="pencil" src={Close}/> </a>
                                                                        <form onSubmit={this.submitDodacie}>
                                                                            <div className="form-group">
                                                                                <label for="name">Meno</label><br/>
                                                                                <input value={this.state.dodaciemeno} onChange={this.handleDodacieMeno} className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">Priezvisko</label><br/>
                                                                                <input value={this.state.dodaciepriezvisko} onChange={this.handleDodaciePriezvisko} className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">E-mail</label><br/>
                                                                                <input value={this.state.dodacieemail} onChange={this.handleDodacieEmail} className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label for="name">Tel. číslo</label><br/>
                                                                                <input value={this.state.dodaciecislo} onChange={this.handleDodacieCislo} className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <div className="col-md-8">
                                                                                    <label for="name">Ulica</label><br/>
                                                                                    <input value={this.state.dodacieulica} onChange={this.handleDodacieUlica} className="form-control" type="text" />
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label for="name">Číslo domu</label><br/>
                                                                                    <input value={this.state.dodaciedom} onChange={this.handleDodacieDom} className="form-control" type="text" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group form-row">
                                                                                <div className="col-md-9">
                                                                                    <label for="name">Mesto</label><br/>
                                                                                    <input value={this.state.dodaciemesto} onChange={this.handleDodacieMesto} className="form-control" type="text" />
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label for="name">PSČ</label><br/>
                                                                                    <input value={this.state.dodaciepsc} onChange={this.handleDodaciePsc} className="form-control" type="text" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="submit" className="btn btn-primary"/>
                                                                            </div>

                                                                        </form>
                                                                    </div>
                                                                    : null}


                                                                </div>
                                                                : <div className="loader">
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#6A9D60"
                                                                        height="100"
                                                                        width="100"
                                                                    />
                                                                </div> }
                                                        </div>


                                                    }
                                                </div>
                                                    </div>
                                                </div>
                                            </div>

                                    }

                            </div>
                        </div>
                        :   <div className="loader">
                            <Loader
                                type="Oval"
                                color="#6A9D60"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </section>
            </div>
        );

    }
}

class Orders extends Component{
    constructor(props){
        super(props);
        this.state = {
            orders: "",
            user: "",
            showDetail: false,
            showOrder: "",
            hrefs: new Array(),
        }
        this.showOrder = this.showOrder.bind(this);
        this.getNazov = this.getNazov.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    loadData = () => {
        var user = localStorage.getItem("user");
        user = JSON.parse(user);
        var thenum = user.billing_address.address_1.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
        var str = user.billing_address.address_1.replace(/[0-9]/g, '');


        this.setState({
            user: user,
        });
        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/orders?customer=" + user.id;
        console.log(url);

        var session_url = url;
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };


        axios.get(session_url,config).then(function(response) {
            console.log(response);

            this.setState({
                orders: response.data,
            });

            // response.data.forEach(function (e) {
            //   this.makePaymentOnline(e.number,e.total);
            // }.bind(this));


        }.bind(this));
    }
    componentDidMount(){
        this.interval = setInterval(() => this.loadData(), 1000);

        var orders = new Array();
        var user = localStorage.getItem("user");
        user = JSON.parse(user);
        var thenum = user.billing_address.address_1.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
        var str = user.billing_address.address_1.replace(/[0-9]/g, '');


        this.setState({
            user: user,
        });

        var url = "https://www.sladuckeovocie.sk/wp-json/wc/v3/orders?customer=" + user.id;
        console.log(url);

        var session_url = url;
        var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
        var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

        var authorizationBasic = window.btoa(uname + ':' + pass);
        var config = {
            "headers": {
                "Authorization": "Basic " + authorizationBasic
            }
        };


        axios.get(session_url,config).then(function(response) {
            console.log(response);

            this.setState({
                orders: response.data,
            });

            response.data.forEach(function (e) {
              this.makePaymentOnline(e.number,e.total);
            }.bind(this));


        }.bind(this));

    }

    makePaymentOnline(id,total){







        var datas = {
            "price": total,
            "id": id,
            "zlava": 0,
        }

            // datas = JSON.stringify(datas);
            console.log(JSON.stringify(datas));

            var url = "https://platby.sladuckeovocie.sk/makeapipayment";
            var self = this;


            axios.post(url, JSON.stringify(datas)).then(data => {

                var h = self.state.hrefs;
                h[id] = data.data;
                self.setState({
                    hrefs: h,
                });

            });




    }

    showOrder(detail){
        this.setState({
            showDetail: false,
        });

        this.state.orders.forEach(function (e) {
           if(e.id == detail){
               this.setState({
                   showOrder: e,
                   showDetail: true,
               });
               return
           }
        }.bind(this));

    }
    hideOrder = () =>{
        this.setState({
            showDetail: false,
            showOrder: "",
        })
    }

    getNazov(nazov){
        if(nazov == 'cancelled'){
            return "Zrušená";
        }
        else if(nazov == 'pending'){
            return "Prijatá";
        }
        else if(nazov == 'processing'){
            return "Prijatá";
        }
        else if(nazov == 'on-hold'){
            return "Prijatá";
        }
        else if(nazov == 'completed'){
            return "Doručená";
        }
        else if(nazov == 'refunded'){
            return "Zrušená";
        }
        else if(nazov == 'failed'){
            return "Zrušená";
        }
        else if(nazov == 'rozpr_nekompletne'){
            return "Rozpracovaná";
        }
        else if(nazov == 'iny_termn'){
            return "Prijatá";
        }
        else if(nazov == 'overene'){
            return "Spracováva sa";
        }
        else if(nazov == 'faktura'){
            return "Spracováva sa";
        }
        else if(nazov == 'overene-2x'){
            return "Spracováva sa";
        }
        else if(nazov == 'gopay'){
            return "Spracováva sa";
        }
        else if(nazov == 'overene-3x'){
            return "Spracováva sa";
        }
        else{
            return nazov;
        }
    }
    render(){
        if(this.state.orders == "") {
            return (

                    <div className="loader">
                        <Loader
                            type="Oval"
                            color="#6A9D60"
                            height="100"
                            width="100"
                        />
                    </div>

            );
        }
        else if(this.state.orders != "nic") {
            return (
                <div>

                    {this.state.showOrder ?


                            <div>
                                <OrderDetail detail={this.state.showOrder} />
                                <br/>
                                <a className="btn btn-primary" onClick={this.hideOrder}>Všetky objednávky</a>
                            </div>
                            :

                        <div>

                            <h2 className="nadpis">Všetky objednávky</h2>
                        <ul className="orderstable">
                            {Array.isArray(this.state.orders) && (this.state.orders.length > 0) ?


                                        <div>
                                            { this.state.orders.map((data,index) =>

                                            <li>

                                                <p><strong>Č. objednávky: </strong>{data.number}</p>
                                                <p><strong>Dátum vytvorenia: </strong>{new Date(data.date_created).toLocaleDateString()}</p>
                                                <p><strong>Suma: </strong>{data.total} €</p>
                                                <p><strong>Stav: </strong>{this.getNazov(data.status)}</p>





                                                <p><strong>Platba: </strong>{ data.payment_method == "bacs" && data.status != "completed" && data.total != 0 && data.status=="on-hold" ?
                                                    <a target="_blank" href={this.state.hrefs[data.number]} className={"btn btn-primary"}>Platba online</a>
                                                    :
                                                    <div className="platbaon">
                                                        {data.total == 0 ?
                                                            <span>Zaplatené</span>
                                                        : <span>Úhrada online nie je možná</span>
                                                        }
                                                    </div> } </p>


                                                <p><a className="btn btn-primary"
                                                      onClick={() => this.showOrder(data.id)}>Podrobnosti objednávky</a></p>
                                            </li>



                                        )
                                    }
                                        </div>

                                : null }
                        </ul>
                        </div>







                    }

                </div>

            );
        }
        else{
            return(
                <h2 className="nadpis centertext mensi">
                    Nemáte vytvorenú žiadnu objednávku
                </h2>
            )
        }
    }
}

class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: "",
            sprava: "",

        }
        this.handleSprava = this.handleSprava.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            detail: this.props.detail,
        });


    }
    getNazov(nazov){
        if(nazov == 'cancelled'){
            return "Zrušená";
        }
        else if(nazov == 'pending'){
            return "Prijatá";
        }
        else if(nazov == 'processing'){
            return "Prijatá";
        }
        else if(nazov == 'on-hold'){
            return "Prijatá";
        }
        else if(nazov == 'completed'){
            return "Doručená";
        }
        else if(nazov == 'refunded'){
            return "Zrušená";
        }
        else if(nazov == 'failed'){
            return "Zrušená";
        }
        else if(nazov == 'rozpr_nekompletne'){
            return "Rozpracovaná";
        }
        else if(nazov == 'iny_termn'){
            return "Prijatá";
        }
        else if(nazov == 'overene'){
            return "Spracováva sa";
        }
        else if(nazov == 'faktura'){
            return "Spracováva sa";
        }
        else if(nazov == 'overene-2x'){
            return "Spracováva sa";
        }
        else if(nazov == 'gopay'){
            return "Spracováva sa";
        }
        else if(nazov == 'overene-3x'){
            return "Spracováva sa";
        }
        else{
            return nazov;
        }
    }

    handleSprava = (event) => {
        this.setState({
            sprava: event.target.value,
        })
    }

    handleSubmit = (event) => {


        var datas = {
            id: this.state.detail.id,
            text: this.state.sprava,
        };


        datas = JSON.stringify(datas);

        axios.post('https://notifysystem.sladuckeovocie.sk/editordermessage',datas,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } )
            .then(res => {

                NotificationManager.info("Vaša požiadavka bola úspešné odoslaná", "Ďakujeme za kontaktovanie", 3000);
            });
        event.preventDefault();
    }

    render() {
        if(this.state.detail != "") {
            return (
                <div>
                    <NotificationContainer />
                    <ul className="orderstable notop">
                        <li>
                            <h2 className="nadpis"> Objednávka #{this.state.detail.number}</h2>
                        </li>
                        <li>
                            <p><strong>Dátum vytvorenia: </strong>{new Date(this.state.detail.date_created).toLocaleDateString()}</p>
                            <p className="noot"><strong>Stav objednávky: </strong>{this.getNazov(this.state.detail.status)}</p>
                        </li>
                        <li>
                            <strong>Produkty</strong>

                            <ul className="nopad">
                                {this.state.detail.line_items.map(data =>
                                    <li>
                                        <p>{data.name}</p>

                                        <p>Množstvo: {data.quantity}</p>
                                        <p>Suma: {Math.round(data.price * data.quantity*100)/100} €</p>
                                    </li>
                                )}

                            </ul>

                            <p className="nbb"><br/><strong>Spolu na úhradu: </strong>{this.state.detail.total} €</p>

                        </li>

                        <li>
                            <div className="row">
                            <div className="col-md-6">

                    <strong>Dodacie údaje</strong>

                    <br/>

                        <div>
                            {this.state.detail.billing.first_name} {this.state.detail.billing.last_name} <br/>
                            {this.state.detail.billing.address_1} {this.state.detail.billing.address_2}, {this.state.detail.billing.postcode} {this.state.detail.billing.city}
                        </div>
                    <br/>
                            </div>
                            <div className="col-md-6">
                                <strong>Spôsob platby</strong>
                                <div>
                                    {this.state.detail.payment_method_title}
                                </div>
                                <strong>Spôsob dopravy</strong>
                                <div>
                                    {this.state.detail.shipping_lines[0].method_title}
                                </div>
                            </div>
                            </div>

                        </li>
                        <li>

                    <h4>Zmena objednávky</h4>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <textarea  value={this.state.sprava} onChange={this.handleSprava} className="form-control textik" name="sprava" required></textarea>
                            </div>
                            <input type="submit" className="btn btn-primary" />
                        </form>

                        </li>
                    </ul>

                </div>
            );
        }
        else{
            return(
                <div></div>
            );
        }
    }
}


export default MyAccount;

