import React, { Component } from 'react';
import axios from 'axios';
import Fb from "./fb.svg";
import Mail from "./mail.svg";
import Phone from "./phone.svg";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = {
            text: "",
            jsonAcf: "",
            email: "",
            gdpr: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeGdpr = this.handleChangeGdpr.bind(this);

    }

    handleChange = (event) => {
        this.setState({
            email: event.target.value,
        })
    }

    handleChangeGdpr = (event) => {
        this.setState({
            gdpr: true,
        });
    }

    handleSubmit = (event) =>{
        event.preventDefault();


        if(this.state.gdpr == false){
            this.setState({
                gdpr2: "* Musíte súhlasiť s podmienkami",
            });
            return;
        }


        var session_url = 'https://us19.api.mailchimp.com/3.0/lists/0a8e2b3abc/members';
        var username = 'user';
        var password = 'cb49d0661e1c5370ef420406fde3e6c4-us19';
        var basicAuth = 'Basic ' + btoa(username + ':' + password);
        var data = '{"email_address:"' + this.state.email +'" }';

        axios.post(session_url, data, {
            headers: { 'Authorization': + basicAuth }
        }).then(function(response) {

        }).catch(function(error) {

        });

    }


    componentDidMount(){
        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {
                this.setState({jsonAcf : res.data,
                    text: res.data[1]['acf']['copyright'],
                });

            });
    }

    render() {
        return(
            <div className="fot">
                {/*<div className="prefot">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-md-4 odmobile">*/}
                                {/*<a href="https://www.facebook.com/Sladucke.Slovenske.Ovocie/" target="_blank"><img className="socials" src={Fb} />Facebook</a><br/><br/>*/}
                                {/*<a href="mailto: info@sladuckeovocie.sk/"><img className="socials" src={Mail} />info@sladuckeovocie.sk</a><br/><br/>*/}

                                 {/*</div>*/}
                            {/*<div className="col-md-4 odmobile">*/}
                                {/*<Link to="/ochrana-sukromia" >Ochrana súkromia</Link><br/>*/}
                                {/*<Link to="/obchodne-podmienky" >Obchodné podmienky</Link><br/>*/}
                                {/*<Link to="/plan-rozvozu" onClick={this.props.setRozvoz}>Plán rozvozu</Link><br/>*/}
                                {/*<a href="https://docs.google.com/forms/d/e/1FAIpQLSeYX0rwsO5hQew0BmVEWrdLd9JAw1ELj4j-_m9VSHeYkMUZHQ/viewform" target="_blank">Formulár na odstúpenie od zmluvy</a><br/>*/}
                                {/*<Link className="hiditnow" to="/zmena-adresy">Formulár na zmenu adresy</Link><br/>*/}
                                {/*<Link to="/obchodne-podmienky" >Orgány dozoru</Link><br/>*/}
                                {/*<Link to="/faq" >Často kladené otázky</Link><br/>*/}
                                {/*<Link to="/kontaktny-formular">Kontaktný formulár</Link><br/>*/}

                            {/*</div>*/}
                            {/*<div className="col-md-4 contact odmobile">*/}
                                {/*<p>Sladučké ovocie s.r.o.</p>*/}
                                {/*<p>IČO: 51029081</p>*/}
                                {/*<p>DIČ: 2120568351</p>*/}
                                {/*<p>IČ DPH: SK21 20 568 351</p>*/}
                                {/*<p>922 23 Dolné Vestenice</p>*/}
                                {/*<p>okres Prievidza</p>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 centermi">*/}
                                {/*<h2 className="ovoslad">Sladučké ovocie s.r.o.</h2>*/}
                                {/*<p>Sladučké ovocie sa zameriava na čerstvé a takmer výlučne SLOVENSKÉ potraviny, ktoré sú podľa môjho názoru tou najlepšou voľbou na trhu. </p>*/}
                            {/*</div>*/}


                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}

                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                {this.state.text}
                            </div>
                            <div className="col-md-6 rr">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;