import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Homepage from "./Homepage/Homepage";
import Header from "./Header";
import Footer from "./Footer";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import Contact from "./Contact/Contact";
import Vop from "./Vop/Vop";
import Gdpr from "./Gdpr/Gdpr";
import About from "./About/About";
import MyAccount from "./MyAccount/MyAccount";
import Rozvoz from "./Rozvoz/Rozvoz";
import Product from "./Product/Product";
import CartIm from "./iconmonstr-shopping-cart-3.svg";
import Faq from "./Faq/Faq";
import Form from "./Form/Form";
import Change from "./Change/Change";
import Loader from 'react-loader-spinner';
import CookieConsent from "react-cookie-consent";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});

class App extends Component {

    constructor(props){
        super(props);
        this.headerElement = React.createRef();
        this.state = {
            cart: false,
            checkout: false,
            contact: false,
            vop: false,
            about: false,
            gdpr: false,
            account:false,
            rozvoz: false,
            incart: 0,
            price: 0,
            products: "",
            adult: false,
            disabledcookies: "",
        };
        this.recount = this.recount.bind(this);
        this.setAdult = this.setAdult.bind(this);
        this.checkCookie = this.checkCookie.bind(this);
        this.showCookieFail = this.showCookieFail.bind(this);

    }

    allfalse = () => {
        this.setState({
            cart: false,
            checkout: false,
            contact: false,
            vop: false,
            gdpr: false,
            rozvoz: false,
            about: false,
            account:false,
        });
    }

    setAdult = () => {
        this.setState({
            adult: true,
        })
    }

    checkCookie(){
        var cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
        }
        return cookieEnabled || this.showCookieFail();
    }

    showCookieFail(){
        this.setState({
            disabledcookies: "Váš prehliadač ma zakázané cookies, pokiaľ nebudú povolené, platba kartou NEBUDE MOŽNÁ!",
        })
    }



    componentDidMount(){
        this.checkCookie();
        var time = localStorage.getItem("time");
        var n = localStorage.getItem("hour");
        if(time != null && n != null){
            time = JSON.parse(time);
            n = JSON.parse(n);


            var d1 = new Date();
            var n1 = d1.getHours();


            // Get today's date
            var todaysDate = new Date();
            var inputDate = new Date(time);
            // call setHours to take the time out of the comparison
            if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
                if(n != n1){

                    localStorage.removeItem("products");
                    localStorage.removeItem("oznam");
                    localStorage.removeItem("time");
                    localStorage.removeItem("cart");
                    localStorage.setItem("time", JSON.stringify(new Date()));
                    localStorage.setItem("hour", JSON.stringify(n1));
                }
            }
            else{

                localStorage.removeItem("products");
                localStorage.removeItem("oznam");
                localStorage.removeItem("time");
                localStorage.removeItem("cart");
                localStorage.setItem("time", JSON.stringify(new Date()));
                localStorage.setItem("hour", JSON.stringify(n1));
            }


        }
        else{
            var d = new Date();
            var n = d.getHours();

            let time = new Date();
           
            localStorage.removeItem("time");
            localStorage.setItem("time", JSON.stringify(time));
            localStorage.setItem("hour", JSON.stringify(n));
        }
        var count = localStorage.getItem("cart");
        if (count != null) {
            count = JSON.parse(count);
            let pric = 0;
            count.forEach(function (e) {
                pric = pric + (Math.round(e[3].PRICE * 100)/100);

            });
            count = count.length;
            this.setState({
                incart: count,
                price: Math.round(pric*100)/100,
            });

        }

        var loc = window.location.href;
        loc = new URL(loc);
        let urlko =  window.location.pathname.replace('/','');

        window.onpopstate = this.onBackButtonEvent;





    }

    componentWillUnmount() {
        super.componentWillUnmount();
        // Unbind listener
        

    }


    onBackButtonEvent=(e) => {
        let urlko =  window.location.pathname.replace('/','');
            if(urlko == '/' +
                '#'){

               this.allfalse();
            }

        e.preventDefault();
        this.goBack();
    }


    setRozvoz = () => {
        this.setState({rozvoz: true});
    }

    setContact  = () => {
        this.allfalse();
        this.setState({
            contact: true,
        });
    }

    setGdpr = () => {

        this.allfalse();

        this.setState({
            gdpr: true,
        });
    }

    setAccount = () => {
        this.allfalse();
        this.setState({
           account: true,
        });
    }

    setVop = () => {
        this.allfalse();
        this.setState({
           vop: true
        });
    }

    toCart = () => {
        this.allfalse();
        this.setState({
           cart: !this.state.cart,
        });
    }

    checkout = () => {

        this.allfalse();
        this.setState({
            checkout: true,
        });


    }

    homepage = () => {
        this.allfalse();
        this.setState({
            cart: false,
            checkout:false,
            contact: false,
            vop: false,
            setAbout: false,
            account: false,
        });

    }

    setAbout = () => {
        this.allfalse();
        this.setState({
           about: true,
        });
    }

    tohome = () => {
        this.allfalse();
        this.setState({
           cart: false,
           checkout: false,
            contact: false,
            vop: false,
            about: false,
            setAbout: false,
            account: false,
        });
    }

    recount = () => {


        var count = localStorage.getItem("cart");
        if(count != null) {
            let pric = 0;
            count = JSON.parse(count);
            count.forEach(function (e) {
                pric = pric + parseFloat(e[3].PRICE);
            });
            count = count.length;

            this.setState({
                incart: count,
                price: Math.round(pric*100)/100,
            });
        }
        else if(count == null){
            this.setState({
                incart: 0,
                price: 0,
            })
        }
    }

  render() {



          return (
              <Router>
                  <Header setAccount={this.setAccount} setContact={this.setContact} tohome={this.tohome}
                          homepage={this.homepage} toCart={this.toCart} onRef={ref => (this.headerElement = ref)}/>
                  <div>
                      <Switch>
                          <Route path="/" exact recount={this.recount}
                                 render={(props) => <Homepage {...props} setAdult={this.setAdult} adult={this.state.adult} recount={this.recount}/>}/>
                          <Route path="/o-nas/" render={(props) => <About {...props} recount={this.recount}/>}/>
                          <Route path="/kontakt/" render={(props) => <Contact {...props} recount={this.recount}/>}/>
                          <Route path="/kosik/" render={(props) => <Cart {...props} recount={this.recount}/>}/>
                          <Route path="/pokladna/" render={(props) => <Checkout {...props} recount={this.recount}/>}/>
                          <Route path="/ochrana-sukromia/"
                                 render={(props) => <Gdpr {...props} recount={this.recount}/>}/>
                          <Route path="/moj-ucet/" render={(props) => <MyAccount {...props} recount={this.recount} /> } />
                          <Route path="/plan-rozvozu/" render={(props) => <Rozvoz {...props} recount={this.recount}/>}/>
                          <Route path="/obchodne-podmienky/"
                                 render={(props) => <Vop {...props} recount={this.recount}/>}/>
                          <Route path="/produkt/:slug" slug="/:slug"
                                 render={(props) => <Product {...props} setAdult={this.setAdult} adult={this.state.adult} recount={this.recount}/>}/>
                          <Route path="/faq/" slug="/:slug"
                                 render={(props) => <Faq {...props} recount={this.recount}/>}/>
                          <Route path="/kontaktny-formular" exact recount={this.recount}
                                 render={(props) => <Form {...props} recount={this.recount}/>}/>
                          <Route path="/zmena-adresy" exact recount={this.recount}
                                 render={(props) => <Change {...props} recount={this.recount}/>}/>
                          <Route recount={this.recount}
                                 render={(props) => <Homepage {...props} setAdult={this.setAdult} adult={this.state.adult} recount={this.recount}/>}/>

                      </Switch>
                  </div>
                  <Link className="cartim" to="/kosik">
                      <div>
                          <img src={CartIm}/>{this.state.incart} pol.<br/>{this.state.price} €
                      </div>
                  </Link>

                  <Footer setRozvoz={this.setRozvoz} setGdpr={this.setGdpr} setVop={this.setVop}/>
                  <CookieConsent buttonText="Súhlasím"
                                 buttonStyle={{color: "#4e503b", fontSize: "13px", backgroundColor: "#6A9D60"}}>
                      Tieto stránky využívajú cookies. Ich ďalším používaním súhlasíte využívaním cookies.
                      Prečítajte si <Link to="/ochrana-sukromia">informácie</Link> o tom, ako cookies používame a ako
                      ich používanie môžete odmietnuť nastavením svojho internetového prehliadača.
                      {this.state.disabledcookies != "" ?
                          <div style={{"textTransform": "uppercase", 'fontWeight': "700"}}>
                              {this.state.disabledcookies}
                          </div>
                          :  null }
                  </CookieConsent>
              </Router>
          );


      }

}

export default App;
