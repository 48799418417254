import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Obrazok from "../rozvoz49.png"
import Obrazok2 from "../vsrozvnoz.png";

class Rozvoz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meno: "",
            cislo: "",
            sprava: "",
            email: "",
            obsah: "",
        };


    }


    componentDidMount(){

        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {

                this.setState({jsonAcf : res.data,

                    obsah: res.data[1]['acf']['obsah'],

                });

            });

        window.scrollTo(0, 0)
    }



    render() {
        if(this.state.obsah != "") {
            return (
                <div className="vop">
                    <section>
                        <div className="mainImage"
                             style={{backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            Plán rozvozu
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row ods odsbot">
                                <div className="col-md-12">

                                    <div className="obal" dangerouslySetInnerHTML={{__html: this.state.obsah}}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
        else{
            return (
                <div className="vop">
                    <section>
                        <div className="mainImage"
                             style={{backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            Plán rozvozu
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="loader">
                                    <Loader
                                        type="Oval"
                                        color="#6A9D60"
                                        height="100"
                                        width="100"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            );
        }

    }
}

export default Rozvoz;

