import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';

import {NotificationContainer, NotificationManager} from 'react-notifications';




class Change extends Component {

    constructor(props) {
        super(props);
        this.state = {
           phone: "",
            id: "",
            address: "",
            city: "",
            postcode: "",
            message: "",
            gdpr: false,
            validate: 123,
        }
        this.handlePhone = this.handlePhone.bind(this);
        this.handleId = this.handleId.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleCity = this.handleCity.bind(this);
        this.handlePostcode = this.handlePostcode.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.handleMessage = this.handleMessage.bind(this);

    }

    componentDidMount(){
        window.scroll(0,0);

        var self = this;

        axios.get("https://notifysystem.sladuckeovocie.sk/api/checkchange").then(data1=> {

            console.log(data1);

            var result = "";
            if(data1.data == 1){
                self.setState({
                    validate: true,
                })
            }
            else{
                self.setState({
                    validate: false,
                })
            }


        });

    }



    handleGdpr(){
        this.setState({
            gdpr: !this.state.gdpr,
        })
    }
    handlePhone(event){
        this.setState({
            phone: event.target.value,
        })
    }
    handleMessage(event){
        this.setState({
            message: event.target.value,
        })

    }
    handleId(event){
        this.setState({
            id: event.target.value,
        })
    }

    handleAddress(event){
        this.setState({
            address: event.target.value,
        })
    }

    handleCity(event){
        this.setState({
            city: event.target.value,
        })
    }

    handlePostcode(event){
        this.setState({
            postcode: event.target.value,
        })
    }

    handleForm(event){

        event.preventDefault();

        var datas = {
            id: this.state.id,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            postcode: this.state.postcode,
            message: this.state.message,
        }
        //axios

        console.log(datas);

        axios.post("https://notifysystem.sladuckeovocie.sk/api/zmena-adresy", JSON.stringify(datas)).then(datas=>{
            NotificationManager.info("", "Ďakujeme, zmena bola úspešne evidovaná", 3000);
            this.setState({
                id: "",
                phone: "",
                address: "",
                city:"",
                message: "",
                postcode: "",
                validate: "success"
            });
        });


        event.preventDefault();



    }









    render() {
        if(this.state.validate == 123){
            return(
                <div className="loader">
                    <Loader
                        type="Oval"
                        color="#6A9D60"
                        height="100"
                        width="100"
                    />
                    <br/>
                </div>
            );
        }
        else if(this.state.validate == false){
            return(
                <div>
                    <div className="objvyp">
                        <p>Ľutujeme, no prijímanie zmien objednávok bolo ukončené</p>
                        <a className="btn btn-primary" href="/">Nakupovať</a>
                    </div>
                </div>
            );
        }
        else if(this.state.validate == true) {
            return (
                <div className="form">
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods odsbot kosods">
                            <div className="col-md-12">

                                <h4 class="headline">Zmena adresy</h4>
                                <p className="center">V deň rozvozu, hláste, prosím, zmenu priamo vodičovi na čísle uvedenom v SMS.</p>
                                {/*<p className="center">Pred tým ako nám napíšete, si môžete prečítať <Link to="/faq">odpovede na najčastejšie kladené otázky</Link>.</p>*/}


                                <br/>

                                <br/><br/>


                                <div>

                                    <form onSubmit={this.handleForm}>
                                        <div className="form-group">
                                            <label>ID objednávky*</label>
                                            <input className="form-control" type="text" placeholder="ID objednávky"
                                                   value={this.state.id} onChange={this.handleId} required={true}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Tel. číslo* </label>
                                            <input placeholder="Tvar: 09XXXXXXXX" className="form-control" type="text"
                                                   value={this.state.phone} onChange={this.handlePhone}
                                                   required={true}/>
                                        </div>

                                        <p><strong>Nová adresa: </strong></p>

                                        <div className="form-group">
                                            <label>Ulica + číslo domu* </label>
                                            <input placeholder="Ulica + číslo" className="form-control" type="text"
                                                   value={this.state.address} onChange={this.handleAddress}
                                                   required={true}/>
                                        </div>

                                        <div className="form-group">
                                            <label>PSČ* </label>
                                            <input placeholder="PSČ" className="form-control" type="text"
                                                   value={this.state.postcode} onChange={this.handlePostcode}
                                                   required={true}/>
                                        </div>

                                        <div className="form-group">
                                            <label>Mesto* </label>
                                            <input placeholder="Mesto" className="form-control" type="text"
                                                   value={this.state.city} onChange={this.handleCity} required={true}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Poznámka </label>
                                            <textarea placeholder="Vaša poznámka" className="form-control" type="text"
                                                      value={this.state.message}
                                                      onChange={this.handleMessage}></textarea>
                                        </div>


                                        <div className="form-group">
                                            <input type="checkbox" required={true}/> <span>SÚHLASÍM SO SPRACOVANÍM OSOBNÝCH ÚDAJOV V SÚLADE S NARIADENÍM GDPR O OCHRANE OSOBNÝCH ÚDAJOV*</span>
                                        </div>
                                        <input type="submit" value="Odoslať" className="btn btn-primary"/>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else if(this.state.validate == "success"){
            return(
            <div className="objsprac">
                <p>Ďakujeme, Vaša zmena bola zaznamenaná!</p>
                <a className="btn btn-primary" href="/">Nakupovať</a>
            </div>
            );
        }


    }
}

export default Change;

