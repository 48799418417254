import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';




class Vop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meno: "",
            cislo: "",
            sprava: "",
            email: "",
            vop: "",
        };


    }

    componentDidMount(){

        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {

                this.setState({jsonAcf : res.data,

                    vop: res.data[1]['acf']['op'],

                });

            });
        window.scrollTo(0, 0)
    }




    render() {
        if(this.state.vop != "") {
            return (

                <div className="vop">
                    <section>
                        <div className="mainImage"
                             style={{backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            Obchodné podmienky
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row ods odsbot">
                                <div className="col-md-12">
                                    <div className="obal" dangerouslySetInnerHTML={{__html: this.state.vop}}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
        else{
            return (
            <section>
                <div className="mainImage"
                     style={{backgroundImage: "url(" + "https://www.sladuckeovocie.sk/wp-content/uploads/2019/03/blueberries-2270379_1920-1.jpg" + ")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text">
                                    Obchodné podmienky
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>


                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="loader">
                                <Loader
                                    type="Bars"
                                    color="black"
                                    height="100"
                                    width="100"
                                />
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </section>

            );
        }

    }
}

export default Vop;

