import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import BackBtn from "../iconmonstr-undo-4.svg";
var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});

class Product extends Component{

    createNotification = (type) => {

        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('Produkt bol úspešne pridaný do košíka');

                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {

                    });
                    break;
            }
        };
    };



    addToCart(id, quantity, var_id, name, price, variations){
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();





        if(this.state.variation_id == ""){
            NotificationManager.warning('', 'Musíte zvoliť variáciu', 3000);
            return false;
        }

        const activeClasses = this.state.activeClasses;

        for(let x=0; x<=100; x++){
            activeClasses[x] = 0;
        }

        this.setState({activeClasses});

        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        if(!(Array.isArray(data))){


            data = new Array();
        }
        else{


        }


        var newCart = new Array();
        var exist = 0;
        data.forEach(function (e) {
            if(e[4].VAR_ID != var_id || e[6].SALE == true){
                newCart.push(e);
            }
            else{

                exist = 1;
                var obj = new Array();
                var prevCount = e[1].QUANTITY;
                var curCount = prevCount + quantity;

                obj.push({"ID": id});
                obj.push({"QUANTITY": curCount});
                obj.push({"NAME": name});
                var price1 = curCount * price;
                obj.push({"PRICE": price1});
                obj.push({"VAR_ID":var_id});
                obj.push({"VAR_NAME":this.state.var_name});
                obj.push({"SALE":false});
                obj.push({"HEIGHT":this.state.actualWeight1});
                obj.push({"SHIP": this.state.free_shippping});
                obj.push({"GLOBAL": this.state.global_shipping});
                if(this.state.notship==1){
                    obj.push({"NOTSHIP": price1});
                }
                else{
                    obj.push({"NOTSHIP": 0});
                }


                newCart.push(obj);
            }
        }.bind(this));

        if(exist == 0) {

            var obj = new Array();

            obj.push({"ID": id});
            obj.push({"QUANTITY": quantity});
            obj.push({"NAME": name});
            obj.push({"PRICE": this.state.price});
            obj.push({"VAR_ID": var_id});
            obj.push({"VAR_NAME":this.state.var_name});
            obj.push({"SALE":false});
            obj.push({"HEIGHT":this.state.actualWeight1});
            obj.push({"SHIP": this.state.free_shippping});
            obj.push({"GLOBAL": this.state.global_shipping});
            if(this.state.notship==1){
                obj.push({"NOTSHIP": this.state.price});
            }
            else{
                obj.push({"NOTSHIP": 0});
            }

            var price = quantity * price;


            newCart.push(obj);
        }

        localStorage.setItem('cart', JSON.stringify(newCart));


        this.props.recount();
        NotificationManager.success('Produkt bol úspešne pridaný do košíka', null, 3000);
        this.setState({
           count: 1,
           price: "",
            var_name: "",
            variation_id: "",

        });
        this.setState({
            //price: "",
            //var_price: "",
            //count: 0,
        })
        var index = 0;
        var idecko = var_id;
        this.state.product.variations.forEach(function (e) {
           if(e.id == idecko) {

               const activeClasses = this.state.activeClasses;

                       activeClasses[index] = 0;

               this.setState({activeClasses});


           }
           index++;
        }.bind(this));
    }


    addToCart2(id, quantity, var_id, name, price, variations, height){

        NotificationManager.listNotify = [];
        NotificationManager.emitChange();

        if(this.state.variation_id == ""){
            NotificationManager.warning('', 'Musíte zvoliť variáciu', 3000);
            return false;

        }
        const activeClasses2 = this.state.activeClasses2;

        for(let x=0; x<=100; x++){
            activeClasses2[x] = 0;
        }

        this.setState({activeClasses2});

        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        if(!(Array.isArray(data))){


            data = new Array();
        }
        else{


        }
        var newCart = new Array();
        var exist = 0;
        data.forEach(function (e) {



            if(e[4].VAR_ID != var_id && e[6].SALE == true){

                newCart.push(e);
            }
            else if(e[4].VAR_ID != var_id && e[6].SALE != true){

                newCart.push(e);
            }
            else if(e[4].VAR_ID == var_id && e[6].SALE == false){

                newCart.push(e);
            }
            else{

                let nazov = "ZĽAVA - " + name;
                exist = 1;
                var obj = new Array();
                var prevCount = e[1].QUANTITY;
                var curCount = prevCount + quantity;
                obj.push({"ID": id});
                obj.push({"QUANTITY": curCount});
                obj.push({"NAME": nazov});
                var price1 = curCount * price;
                obj.push({"PRICE": price1});
                obj.push({"VAR_ID":var_id});
                obj.push({"VAR_NAME":this.state.var_name});
                obj.push({"SALE":true});
                obj.push({"HEIGHT":parseFloat(this.state.acth)});
                obj.push({"SHIP": this.state.free_shippping});
                obj.push({"GLOBAL": this.state.global_shipping});
                if(this.state.notship==1){
                    obj.push({"NOTSHIP": price1});
                }
                else{
                    obj.push({"NOTSHIP": 0});
                }

                newCart.push(obj);
            }
        }.bind(this));

        if(exist == 0) {

            var obj = new Array();
            let nazov = "ZĽAVA - " + name;
            obj.push({"ID": id});
            obj.push({"QUANTITY": quantity});
            obj.push({"NAME": nazov});
            obj.push({"PRICE": this.state.pricesale});
            obj.push({"VAR_ID": var_id});
            obj.push({"VAR_NAME":this.state.var_name});
            obj.push({"SALE":true});
            obj.push({"HEIGHT":parseFloat(this.state.acth)});
            obj.push({"SHIP": this.state.free_shippping});
            obj.push({"GLOBAL": this.state.global_shipping});
            if(this.state.notship==1){
                obj.push({"NOTSHIP": this.state.pricesale});
            }
            else{
                obj.push({"NOTSHIP": 0});
            }
            var price = quantity * price;


            newCart.push(obj);
        }

        localStorage.setItem('cart', JSON.stringify(newCart));


        this.props.recount();
        NotificationManager.success('Produkt bol úspešne pridaný do košíka', null, 3000);
        this.setState({
            //price: "",
            //var_price: "",
            //count: 0,
        })

        if(((quantity * this.state.acth) + this.state.actsalh) >= this.state.max){
            NotificationManager.warning("V prípade záujmu o viac kusov si môžete vytvoriť novú objednávku", "Prekročili ste limit akcie", 3000);
        }

        this.setState({
            actsalh: (quantity * this.state.acth) + this.state.actsalh,
            count2: 1,
            pricesale: this.state.pricesale,

        });
        var minvar = "";
        this.state.product.variations.forEach(function (e) {
           if(e.dimensions.width == 1 && minvar == ""){
               minvar = e;
           }
        }.bind(this));





        var podm = ((parseFloat(((quantity * this.state.acth) + this.state.actsalh))+parseFloat(minvar.dimensions.height)));


        if(podm > this.state.max ){
            this.setState({
                shownosalevar: true,
                showsalevar: false,
            })
        }

        this.setState({
            count2: 1,
            pricesale: "",
            var_name: "",
            variation_id: "",

        });
        this.setState({
            //price: "",
            //var_price: "",
            //count: 0,
        });

        var index = 0;
        var idecko = var_id;
        this.state.product.variations.forEach(function (e) {
            if(e.id == idecko) {

                const activeClasses = this.state.activeClasses;

                activeClasses[index] = 0;

                this.setState({activeClasses});


            }
            index++;
        }.bind(this));



    }

    constructor(props){
        super(props);
        this.state = {
            product: 0,
            count: 1,
            count2: 1,
            variations: new Array(),
            price: "",
            var_price: "",
            var_pricesale: "",
            variation_id: "",
            var_name: "",
            activeClasses: [],
            activeClasses2: [],
            array1: "",
            percentual: 0,
            array2: "",
            array3: "",
            notship: false,
            ch: false,
            min: "",
            max: "",
            hlaska: "",
            salprice: "",
            actualWeight: "",
            actualWeight1: "",
            actualWeight2: "",
            varshow: "none",
            varp: true,
            stock: {
                stock: 1
            },
            showsalevar: true,
            shownosalevar: false,
            salecanadd: 0,
            pricesale: 0,
            actsalh: 0,
            actsalhcounter: 0,
            acth: "",
            stockloaded: false,
            stockallowed: "",
            icr: false,
            icr2: false,
            free_shippping: false,
            global_shipping: false,
            justone: 0,
            povolvarko: 1,
            firsid: "",
            adult: false,
            isadult: false,
            isselectedvar: false,


        }

        this.showsalevar = this.showsalevar.bind(this);
        this.shownosalevar = this.shownosalevar.bind(this);
        this.addvarfirst = this.addvarfirst.bind(this);
        this.setAdult = this.setAdult.bind(this);
        this.triggerClick = this.triggerClick.bind(this);


    }

    triggerClick = () => {
        if(this.state.isselectedvar == false) {
            var link = document.getElementsByClassName('var');
            if(link != null) {
                link[0].click();
                this.setState({
                    isselectedvar: true,
                });
            }
        }
    }


    setAdult = () =>{
       // alert("click");
        this.setState({
            adult: true,
        })
        this.props.setAdult();
    }
    showsalevar = () => {

        var activeClasses = this.state.activeClasses;

        var xko = 0;
        activeClasses.forEach(function (e) {
                activeClasses[xko] = 0;
            xko++;

        });

        this.setState({activeClasses});


        var activeClasses2 = this.state.activeClasses2;

        var xko2 = 0;
        activeClasses2.forEach(function (e) {
            activeClasses2[xko2] = 0;
            xko2++;

        });

        this.setState({activeClasses2});

        var variation_id = this.state.variation_id;

        var index = 0;
        var indexik = 0;

        if(variation_id != ""){

            this.state.product.variations.forEach(function (e) {
                if(variation_id == e.id){
                    indexik = index;
                }
                index++;
            }.bind(this));



            if(activeClasses[indexik] == 1){

                //activeClasses[indexik] = !activeClasses[indexik];


               // this.setState({activeClasses});

                this.setState({
                    count: 1,
                    price: "",
                    var_name: "",
                    variation_id: "",
                });

                return;
            }
        }

        this.setState({
            count: 1,
            price: "",
            var_name: "",
            variation_id: "",


            pricesale: "",
            count2: 1,
            var_pricesale: "",
            variation_id: "",
            showsalevar: true,
            shownosalevar: false,
        })
    }

    shownosalevar = () => {

        var activeClasses = this.state.activeClasses;

        var xko = 0;
        activeClasses.forEach(function (e) {
            activeClasses[xko] = 0;
            xko++;

        });

        this.setState({activeClasses});


        var activeClasses2 = this.state.activeClasses2;

        var xko2 = 0;
        activeClasses2.forEach(function (e) {
            activeClasses2[xko2] = 0;
            xko2++;

        });

        this.setState({activeClasses2});

        var variation_id = this.state.variation_id;

        var index = 0;
        var indexik = 0;

        if(variation_id != ""){

            this.state.product.variations.forEach(function (e) {
                if(variation_id == e.id){
                    indexik = index;
                }

                index++;
            }.bind(this));

          //  var activeClasses = this.state.activeClasses;

            if(activeClasses[indexik] == 1){

               // activeClasses[indexik] = !activeClasses[indexik];


               // this.setState({activeClasses});

                this.setState({
                    count2: 1,
                    pricesale: "",
                    var_name: "",
                    variation_id: "",
                    showsalevar: false,
                    shownosalevar: true,

                });

                return;

            }

        }


        this.setState({
            count2: 1,
            pricesale: "",
            var_name: "",
            variation_id: "",
            showsalevar: false,
            shownosalevar: true,


            price: "",
            count: 1,
            var_price: "",
            var_pricesale: "",
            variation_id: "",
            showsalevar: false,
            shownosalevar: true,
        })
    }


    findArrayElementByTitle(array, key) {
        //   alert("1");

        return array.find((element) => {


            if(element.key == key){
                //   console.log(element.value)
                return element;
            }
        })
    }

    componentDidMount(){
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();

        window.scrollTo(0, 0);
        this.interval = setInterval(() => this.triggerClick(), 500);

        var urlko = document.URL.split("/");
        let url = urlko[4];

        this.setState({
            adult: this.props.adult,
        })



        var productsall;

        if(localStorage.getItem("products")!=null){

            let products1 = JSON.parse(localStorage.getItem("products"));


            products1.forEach(function (e) {

                if(url == e.attributes[0].slug) {

                    if(e.min_sale != ""){
                        this.setState({
                            min: e.min_sale
                        })
                    }
                    else{
                        this.setState({
                            min: 0
                        })
                    }
                    if(e.max_sale != ""){
                       this.setState({
                           max: e.max_sale,
                           salecanadd: e.max_sale,
                       })
                    }
                    else{
                        this.setState({
                            max: 0
                        })
                    }

                    axios.get('https://notifysystem.sladuckeovocie.sk/stock/getstock/'+e.id)
                        .then(res=>{


                            this.setState({
                                stock: res.data,
                                stockloaded: true,
                            });
                        });

                    this.setState({
                        product: e,
                        min: e.min_sale,
                        max: e.max_sale,
                        salprice: e.sale_price,
                    });

                    var icr = 0;
                    var icr2 = 0;
                    e.variations.forEach(function (e) {

                        if(icr2 == 6){
                            this.setState({
                                icr2: true,
                            })
                        }

                        if(icr == 6){
                            this.setState({
                                icr: true,
                            })
                        }


                        if(e.dimensions.width == 1){
                            icr2++;
                        }
                        if(e.dimensions.width != 1){
                            icr++;
                        }



                    }.bind(this));

                    var prod= e;
                    var cart1 = localStorage.getItem("cart");
                    var totalh1 = 0;
                    if(cart1 != null){
                        cart1 = JSON.parse(cart1);

                        cart1.forEach(function (e) {

                            if(e[6].SALE && prod.id == e[0].ID){

                                totalh1 = totalh1 + (e[7].HEIGHT * e[1].QUANTITY);

                                this.setState({
                                    actsalh: totalh1,
                                });
                            }
                        }.bind(this));
                    }


                }
            }.bind(this));




        }
        else {
            let allproductswoo;


            var url2 = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products?slug=" + url;


            var uname = 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c';
            var pass = 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9';

            var authorizationBasic = window.btoa(uname + ':' + pass);
            var config = {
                "headers": {
                    "Authorization": "Basic " + authorizationBasic
                }
            };
            axios.get(url2, config).then(data=> {
            //    console.log(data.data[0]);
            var data = data.data[0];




         //   console.log(data.meta_data);
            var min_sale = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title6");
            min_sale = min_sale.value;

       //     console.log(min_sale);
            var max_sale = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title7");
            max_sale = max_sale.value;
        //        console.log(max_sale);
            var sale_price = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title8");
            sale_price = sale_price.value;

                var dopr = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title24");

                if(dopr!=null && dopr.value ==1){
                    this.setState({
                        notship: true,
                    })

                }


                var pricen = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title23")
                if(pricen != null) {
                    this.setState({
                        percentual: (100 - pricen.value)/100,
                    })
                    //   alert((100 - pricen.value)/100)

                }
       //        console.log(sale_price);

                //allproductswoo = JSON.parse(res.body);




                        axios.get('https://notifysystem.sladuckeovocie.sk/stock/getstock/'+data.id)
                            .then(res=>{
              //                  console.log(res.data)
                                this.setState({
                                    stock: res.data,
                                    stockloaded: true,
                                });
                            });

                        if(min_sale != ""){
                            this.setState({
                                min: min_sale
                            })
                        }
                        else{

                            this.setState({
                                min: 0
                            })
                        }
                        if(max_sale != ""){
                            this.setState({
                                max: max_sale,
                                salecanadd: max_sale,
                            })
                        }
                        else{

                            this.setState({
                                max: 0
                            })
                        }
                var fotkamobil = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title16");
                        var shipko = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title17");
                  //      console.log(shipko);
                        if(shipko!=null) {
                            if (shipko.value != "") {
                                this.setState({
                                    free_shippping: shipko.value,
                                });


                                var mj = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title3");
                                if(mj!=null) {

                                    var strin = "Pri nákupe nad " + shipko.value + mj.value + " máte dopravu zdarma!"
                                    this.setState({
                                        hlaska: strin,
                                    })
                                }

                            }
                        }

                    var shipko2 = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title18");

                    if(shipko2!=null) {
                        if (shipko2.value != "") {
                            this.setState({
                                global_shipping: shipko2.value,
                            });
                        }
                    }





                        data.fotkamobil = fotkamobil.value;
                        data.title = data.name;
                        data.price_html_sale = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title9");
                        data.price_html_sale = data.price_html_sale.value;
                        data.pricko = this.findArrayElementByTitle(data.meta_data,"custom_text_field_title15");
                        data.pricko = data.pricko.value;
                        data.mj = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title3");
                        data.mj = data.mj.value;

                        var isad = this.findArrayElementByTitle(data.meta_data, "custom_text_field_title21");
                        if(isad != undefined) {

                            if (isad.value) {
                                this.setState({
                                    isadult: true,
                                })
                            }
                        }



                        var url3 = "https://www.sladuckeovocie.sk/wp-json/wc/v3/products/"+data.id+"/variations?stock_status=instock&per_page=99";

                    axios.get(url3, config).then(data1=> {


                        data.variations = data1.data.sort(function(a, b){return a.menu_order - b.menu_order});
                  //      console.log(data);




                        this.setState({
                            product: data,
                            min: min_sale,
                            max: max_sale,
                            salprice: sale_price,

                        })









                        var icr = 0;
                        var icr2 = 0;
                        data.variations.forEach(function (e) {

                            if(icr2 == 6){
                                this.setState({
                                    icr2: true,
                                })
                            }

                            if(icr == 6){
                                this.setState({
                                    icr: true,
                                })
                            }


                            if(e.dimensions.width == 1){
                                icr2++;
                            }
                            if(e.dimensions.width != 1){
                                icr++;
                            }



                        }.bind(this));

                        var prod= data;
                        var cart1 = localStorage.getItem("cart");
                        var totalh1 = 0;
                        if(cart1 != null){
                            cart1 = JSON.parse(cart1);

                            cart1.forEach(function (e) {

                                if(e[6].SALE && prod.id == e[0].ID){

                                    totalh1 = totalh1 + (e[7].HEIGHT * e[1].QUANTITY);

                                    this.setState({
                                        actsalh: totalh1,
                                    });
                                }
                            }.bind(this));
                        }





                    });



               // localStorage.setItem("products", JSON.stringify(data.products));

            });

        }











        // wooCommerce.get(url, function(err, data, res){

        //     this.setState({
        //         product: data.products[0],
        //     });
        //
        //
        // }.bind(this));




    }



    slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    addvar = (id, price,name,index,weight) =>{




        const activeClasses = this.state.activeClasses;

        for(let x=0; x<=100; x++){
            activeClasses[x] = 0;
        }



        var xko = 0;
        activeClasses.forEach(function (e) {
            if(index != xko){
                activeClasses[xko] = 0;
            }
            else{
                activeClasses[xko] = 1;
            }
            xko++;

        });

        this.setState({activeClasses});





        // var xko = 0;
        //
        // arrayko.find((element, index) => {
        //      if(element === id){
        //         arrayko.splice(index);
        //         xko = 1;
        //      }
        // })
        // if(xko == 0) {
        //     arrayko.push(id);
        // }

        this.setState({
            price: price,
            var_price: price,
            actualWeight1: weight,
            count: 1,
        });

        this.setState({
            variation_id: id,
        });


        this.setState({

            var_name: name,
        });


    }


    addvarfirst = (id, price,name,index,weight) =>{



        if(this.state.povolvarko == 1) {
            this.setState({
                povolvarko: 0,
                firsid: index,
            })
            const activeClasses = this.state.activeClasses;

            for (let x = 0; x <= 100; x++) {
                activeClasses[x] = 0;
            }


            var xko = 0;
            activeClasses.forEach(function (e) {
                if (index != xko) {
                    activeClasses[xko] = 0;
                }
                else {
                    activeClasses[xko] = 1;
                }
                xko++;

            });

            this.setState({activeClasses});


            this.setState({
                price: price,
                var_price: price,
                actualWeight1: weight,
                count: 1,
            });

            this.setState({
                variation_id: id,
            });


            this.setState({

                var_name: name,
            });
        }
        else if(this.state.povolvarko == 0){

            if(index != this.state.firsid) {
                const activeClasses = this.state.activeClasses;
                var index = 0;
                activeClasses.forEach(function (e) {
                    activeClasses[index] = 0;

                    this.setState({activeClasses});
                    index++;

                }.bind(this));

                this.setState({
                    count: 1,
                    price: "",
                    var_name: "",
                    variation_id: "",

                });

                this.setState({povolvarko: 2});
            }



        }




    }

    addvar2 = (id, price,name,index,weight,height) =>{
        const activeClasses2 = this.state.activeClasses2;

        for(let x=0; x<=100; x++){
            activeClasses2[x] = 0;
        }


        activeClasses2[index] = !activeClasses2[index];
        var xko = 0;


        var xko = 0;
        activeClasses2.forEach(function (e) {
            if(index != xko){
                activeClasses2[xko] = 0;
            }
            else{
                activeClasses2[xko] = 1;
            }
            xko++;

        });

        this.setState({activeClasses2});

        // var xko = 0;
        //
        // arrayko.find((element, index) => {
        //      if(element === id){
        //         arrayko.splice(index);
        //         xko = 1;
        //      }
        // })
        // if(xko == 0) {
        //     arrayko.push(id);
        // }

        this.setState({
            pricesale: price,
            count2: 1,
            var_pricesale: price,
            actualWeight2: weight,
            acth: height,
        });

        this.setState({
            variation_id: id,
        });


        this.setState({

            var_name: name,
        });




        // if(Math.round(weight) >= this.state.min && Math.round(weight) <= this.state.max && Math.round(weight*this.state.count) <= this.state.max){
        //     this.setState({
        //         pricesale: this.state.salprice * this.state.count,
        //         //var_price: this.state.salprice,
        //     });
        // }


    }

    addvarfirst2 = (id, price,name,index,weight,height) =>{

        if(this.state.povolvarko == 1) {
            this.setState({
                povolvarko: 0,
                firsid: index,
            })

            const activeClasses2 = this.state.activeClasses2;

            for (let x = 0; x <= 100; x++) {
                activeClasses2[x] = 0;
            }


            activeClasses2[index] = !activeClasses2[index];
            var xko = 0;


            var xko = 0;
            activeClasses2.forEach(function (e) {
                if (index != xko) {
                    activeClasses2[xko] = 0;
                }
                else {
                    activeClasses2[xko] = 1;
                }
                xko++;

            });

            this.setState({activeClasses2});

            // var xko = 0;
            //
            // arrayko.find((element, index) => {
            //      if(element === id){
            //         arrayko.splice(index);
            //         xko = 1;
            //      }
            // })
            // if(xko == 0) {
            //     arrayko.push(id);
            // }

            this.setState({
                pricesale: price,
                count2: 1,
                var_pricesale: price,
                actualWeight2: weight,
                acth: height,
            });

            this.setState({
                variation_id: id,
            });


            this.setState({

                var_name: name,
            });

        }
        else if(this.state.povolvarko == 0){

            if(index != this.state.firsid) {
                const activeClasses = this.state.activeClasses2;
                var index = 0;
                activeClasses.forEach(function (e) {
                    activeClasses[index] = 0;

                    this.setState({activeClasses});
                    index++;

                }.bind(this));

                this.setState({
                    count2: 1,
                    pricesale: "",
                    var_name: "",
                    variation_id: "",

                });

                this.setState({povolvarko: 2});
            }



        }




        // if(Math.round(weight) >= this.state.min && Math.round(weight) <= this.state.max && Math.round(weight*this.state.count) <= this.state.max){
        //     this.setState({
        //         pricesale: this.state.salprice * this.state.count,
        //         //var_price: this.state.salprice,
        //     });
        // }


    }

    decrement=()=>{
        if(this.state.count == 1){
            return
        }

        if(this.state.count <= 0){
            this.setState({
                count: 1,
            })
            this.setState({
                price: "",
            });
            return false;
        }
        else{
            this.setState({
                count: this.state.count - 1 ,

            });

        }
        this.setState({
            price: this.state.var_price * (this.state.count -1)
        });

        // if(Math.round(this.state.actualWeight1*this.state.count-1) >= this.state.min && Math.round(this.state.actualWeight1*this.state.count-1) <= this.state.max && Math.round(this.state.actualWeight1 * this.state.count-1) <= this.state.max){
        //
        //     this.setState({
        //         price: this.state.salprice * this.state.count,
        //         //var_price: this.state.salprice,
        //     });
        // }
    }
    decrement2=()=>{

        if(this.state.var_pricesale == ""){
            NotificationManager.warning("Zvoľte si najprv variáciu");
            return;
        }
        if(this.state.count2 == 1){
            return
        }
        if(this.state.count2 <= 0){
            this.setState({
                count2: 1,
            })
            this.setState({
                pricesale: "",
            });
            return false;
        }
        else{
            this.setState({
                count2: this.state.count2 - 1 ,
                actsalhcounter: (this.state.count2 - 1) * this.state.acth,

            });

        }
        this.setState({
            pricesale: this.state.var_pricesale * (this.state.count2 -1)
        });


    }
    increment=()=>{
        if(this.state.var_price == ""){
            NotificationManager.warning("Zvoľte si najprv variáciu");
            return;
        }


        this.setState({
            count: this.state.count + 1,

        });

        this.setState({
            price: this.state.var_price * (this.state.count+1)
        });

        // if(Math.round(this.state.actualWeight1*this.state.count+1) >= this.state.min && Math.round(this.state.actualWeight1*this.state.count+1) <= this.state.max && Math.round(this.state.actualWeight1*this.state.count+1) <= this.state.max){
        //
        //     this.setState({
        //         price: this.state.salprice * this.state.count,
        //         //var_price: this.state.salprice,
        //     });
        // }
    }

    increment2=()=>{

        if(this.state.var_pricesale == ""){
            NotificationManager.warning("Zvoľte si najprv variáciu");
            return;
        }

        if((this.state.count2 + 1) * this.state.acth <= this.state.max && (this.state.actsalh + (this.state.count2 + 1) * this.state.acth) <= this.state.max ) {

            this.setState({
                count2: this.state.count2 + 1,
                actsalhcounter: (this.state.count2 + 1) * this.state.acth,

            });

            this.setState({
                pricesale: this.state.var_pricesale * (this.state.count2 + 1)
            });
        }
        else{
            NotificationManager.warning("V prípade záujmu o viac kusov si môžete vytvoriť novú objednávku", "Prekročili ste limit akcie", 3000);
            return;
        }



        // if(Math.round(this.state.actualWeightSale1*this.state.count2+1) >= this.state.min && Math.round(this.state.actualWeight1*this.state.count+1) <= this.state.max && Math.round(this.state.actualWeight1*this.state.count+1) <= this.state.max){
        //
        //     this.setState({
        //         price: this.state.salprice * this.state.count,
        //         //var_price: this.state.salprice,
        //     });
        // }
    }
    gb = () => {
        window.history.back();
    }

    showVars = () => {
        this.setState({
            varshow: "inline-block",
            varp: false,
        });
    }
    componentDidUpdate(){

        if(Array.isArray(this.state.product.variations)) {
            if (this.state.product.variations.length == 1) {
                if (this.state.ch == false) {
                    let data = this.state.product.variations[0];
                    if (this.state.price == "") {
                       // this.addvar(data.id, data.price, this.getVarName(0), 0);
                    }
                    if (this.state.ch != true) {
                        this.setState({
                            ch: true,
                        })
                    }
                }
            }
        }
        var zero = 0;
        var nezl = 0;
        var prodko;
        var indexik;
        var actvar;
        if(Array.isArray(this.state.product.variations)) {
            this.state.product.variations.forEach(function (e) {
                if (e.dimensions.width == "1") {
                    if (this.state.pricesale == "") {
                     //   this.addvar2(e.id, e.price, this.getVarName(0), zero,e.weight, e.dimensions.height); //oznaci prvu zlavnenu
                    }
                }
                else{
                    nezl++;
                    prodko = e;
                    indexik = zero;
                }
                zero++;
            }.bind(this));

            if(nezl == 1){
                if (this.state.price == "") {
                   // this.addvar(prodko.id, prodko.price, this.getVarName(indexik), indexik);
                }
            }
        }

    }
    render() {
        if(this.state.product == 0 && (this.state.max=="" || this.state.min=="") && this.state.stockloaded == false && !Array.isArray(this.state.product.variations)){
            return(
                <div className="minh">
                    <NotificationContainer/>
                    <div className="loader ccc">

                        <Loader
                            type="Oval"
                            color="#6A9D60"
                            height="100"
                            width="100"
                        />
                    </div>
                </div>
            );
        }
        else if(this.state.product == 0 || !Array.isArray(this.state.product.variations)){
            return(
                <div className="minh">
                    <NotificationContainer/>
                    <div className="loader ccc">

                        <Loader
                            type="Oval"
                            color="#6A9D60"
                            height="100"
                            width="100"
                        />
                    </div>
                </div>
            );
        }
        else if(this.state.adult == false && this.state.isadult == true){
            return(
                <div className="adultprod">
                    <p>Na zobrazenie tohto produktu musíte mať 18 rokov!</p>
                    <a onClick={this.setAdult} className="btn btn-primary">Mám 18 rokov</a>
                </div>

            )
        }
        else if((this.state.stock.stock > 0 || this.state.stock.stock == -1 || this.state.stock.allowed == 1) && (this.state.max != 0 || this.state.min != 0) && Array.isArray(this.state.product.variations) ){
            return (
                //ZLAVNENEEEEEEEEEEEEEEEEEEEEEEe
                //ZLAVENEEEEEEEEEEEEEEEEEEEEEEEEEE
                <div className="productsect">


                    {Array.isArray(this.state.product.categories) ?
                    <Link className="backbtn" to={'/?cat='+this.slugify(this.state.product.categories[0])} ><img src={BackBtn}/></Link>
                        : null}
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods odsbot">
                            <div className="col-md-6">
                                <div className="product proddetail"
                                     style={{backgroundImage: `url(${this.state.product.fotkamobil})`}}></div>
                            </div>
                            <div className="col-md-6">
                                <h2 className="nadpisProd nadpisDetail">{this.state.product.title}</h2>
                                {/*{ this.state.stock.allowed == 0 ?*/}
                                {/*<p>Na sklade</p>*/}
                                    {/*: <p>Na sklade</p> }*/}
                                {/*<a onClick={this.showsalevar} className={this.state.showsalevar ? 'actvar varko btn btn-primary zlavnene' : 'btn varko btn-primary zlavnene' }>Akciové varianty</a> &nbsp;<a onClick={this.shownosalevar} className={this.state.shownosalevar ? 'btn varko btn-primary actvar' : 'btn varko btn-primary' }>Neakciové varianty</a>*/}
                                {/*<br/><br/>*/}
                                {this.state.showsalevar == true && this.state.actsalh < this.state.max ?
                                    <div className="obalik">
                                        { this.state.actsalh < this.state.max ?
                                        <div>
                                            <div className="variations">
                                                {/*<h6 className="minihead">Akciové varianty</h6>*/}
                                                {/*<p>Rozdiel sa týka len ceny, kvalita je rovnaká ako u neakciových variánt.</p>*/}
                                                {this.state.max != 0 ?
                                                <p>Maximálne množstvo odberu akciových variant: {this.state.max} {this.state.product.mj}</p> : null }


                                                {
                                                    this.state.product.variations.map((data, index) => {
                                                            // if (index < 6 && data.dimensions.height <= this.state.max && data.dimensions.width == 1 && (this.state.actsalh * data.dimensions.height) <= this.state.max) return (
                                                            //
                                                            //     <a className={this.state.activeClasses2[index] ? "active var" : "inactive var"}
                                                            //        onClick={() => this.addvar2(data.id, data.price, this.getVarName(index), index, data.weight,data.dimensions.height)}>
                                                            //
                                                            //         {this.getVarName(index)}
                                                            //     </a>)




                                                        if (data.dimensions.width == 1 && this.state.actsalh <= this.state.max && (this.state.max - this.state.actsalh) >= data.dimensions.height && (data.weight <= this.state.stock.stock || this.state.stock.allowed == 1 )) return (

                                                            <a className={this.state.activeClasses2[index] == 1 ? "active var zlavnene" : "inactive var zlavnene"}
                                                               onClick={() => this.addvar2(data.id, data.price, data.attributes[0].option, index, data.weight,data.dimensions.height)}>
                                                                {this.addvarfirst2(data.id, data.price, data.attributes[0].option, index, data.weight,data.dimensions.height)}
                                                                {data.attributes[0].option}
                                                            </a>)

                                                            // if (index >= 6 && data.dimensions.width == 1 && (this.state.actsalh * data.dimensions.height) <= this.state.max) return (
                                                            //     <a style={{display: this.state.varshow}}
                                                            //        className={this.state.activeClasses2[index] ? "active var" : "inactive var"}
                                                            //        onClick={() => this.addvar2(data.id, data.price, this.getVarName(index), index, data.weight,data.dimensions.height)}>
                                                            //
                                                            //         {this.getVarName(index)}
                                                            //     </a>)


                                                        }
                                                    )
                                                }

                                                {/*{this.state.icr2 == true ?*/}
                                                    {/*<a className={this.state.varp ? 'btn btn-primary blocked' : 'btn btn-primary hidden blocked'}*/}
                                                       {/*onClick={this.showVars}> Zobraziť ďalšie </a> : null*/}
                                                {/*}*/}
                                            </div>
                                            <div className="count">
                                                <h6 className="minihead">Počet kusov</h6>
                                                <span><a className="minus" onClick={this.decrement2}>-</a><a
                                                    className="incrementing">{this.state.count2}</a><a className="plus"
                                                                                                      onClick={this.increment2}>+</a> </span>
                                            </div>
                                            <br/>
                                            {
                                                this.state.salprice != "" && ((this.state.count * this.state.actualWeight1 - this.state.min + 1) >= 0) ? [

                                                        (this.state.count * this.state.actualWeight1 - this.state.min + 1) <= this.state.max ?
                                                            <div>{this.state.count * this.state.actualWeight1 - this.state.min + 1}kg
                                                                v zľave</div>
                                                            : <div>{this.state.max}kg v zľave</div>

                                                    ]
                                                    :
                                                    null

                                            }


                                            <div className="row cenyzm">
                                                <div className="col-6">
                                                    {this.state.pricesale == "" ?
                                                        <p className="price detailprice">od: {this.state.product.price_html_sale}</p>
                                                        :
                                                        <p className="price detailprice">Akciová cena: {(Math.round(this.state.pricesale * 100) / 100).toFixed(2)} €</p>
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {this.state.pricesale != "" ?
                                                        <div>
                                                            <p className="price detailprice togreyize"> Pôvodná cena: <del>{ ( (Math.round((this.state.pricesale / this.state.percentual) * 100) / 100 ).toFixed(2))   } €</del></p>
                                                        </div>
                                                        : null }
                                                </div>

                                            </div>
                                            <div className="addtocart">
                                                <a className="var add-to-cart"
                                                   onClick={() => this.addToCart2(this.state.product.id, this.state.count2, this.state.variation_id, this.state.product.title, this.state.var_pricesale,this.state.acth)}>Pridať
                                                    do košíka</a>
                                            </div>


                                            {Array.isArray(this.state.product.categories) ?
                                            <Link className="var add-to-cart backbb"
                                                  to={'/?cat=' + this.slugify(this.state.product.categories[0])}>Späť</Link>
                                                : null }
                                        </div> : <p>Už máte v košíku maximálne povolené množstvo: {this.state.actsalh}</p> }
                                    </div>
                                    :
                                    <div>
                                        { this.state.showsalevar == true ?
                                        <p>Už máte v košíku maximálne povolené množstvo: {this.state.actsalh} {this.state.product.mj}</p>
                                            : null }
                                    </div>
                                }

                                {this.state.shownosalevar == true ?
                                    <div className="obalik">
                                        <div className="variations">
                                            <h6 className="minihead">Varianta</h6>
                                            {/*<p>Zlava: {this.state.salprice}</p>*/}
                                            {/*<p>Min.  {this.state.min}</p>*/}
                                            {/*<p>Max.  {this.state.max}</p>*/}
                                            <p></p>
                                            {
                                                this.state.product.variations.map((data, index) => {
                                                        if (data.dimensions.width != 1 && data.dimensions.width != 2 && (data.weight <= this.state.stock.stock || this.state.stock.allowed == 1 )) return (

                                                            <a className={this.state.activeClasses[index] == 1 ? "active var" : "inactive var"}
                                                               onClick={() => this.addvar(data.id, data.price, data.attributes[0].option, index, data.weight)}>



                                                                {data.attributes[0].option}
                                                            </a>)

                                                        // if (index >= 6 && data.dimensions.width != 1) return (
                                                        //     <a style={{display: this.state.varshow}}
                                                        //        className={this.state.activeClasses[index] ? "active var" : "inactive var"}
                                                        //        onClick={() => this.addvar(data.id, data.price, this.getVarName(index), index, data.weight)}>
                                                        //
                                                        //         {this.getVarName(index)}
                                                        //     </a>)


                                                    }
                                                )
                                            }
                                            {/*{this.state.icr == true ?*/}
                                                {/*<a className={this.state.varp ? 'btn btn-primary blocked' : 'btn btn-primary hidden blocked'}*/}
                                                   {/*onClick={this.showVars}> Zobraziť ďalšie </a> : null*/}
                                            {/*}*/}
                                        </div>
                                        <div className="count">
                                            <h6 className="minihead">Počet kusov</h6>
                                            <span><a className="minus" onClick={this.decrement}>-</a><a
                                                className="incrementing">{this.state.count}</a><a className="plus"
                                                                                                  onClick={this.increment}>+</a> </span>
                                        </div>
                                        <br/>
                                        {
                                            this.state.salprice != "" && ((this.state.count * this.state.actualWeight1 - this.state.min + 1) >= 0) ? [

                                                    (this.state.count * this.state.actualWeight1 - this.state.min + 1) <= this.state.max ?
                                                        <div>{this.state.count * this.state.actualWeight1 - this.state.min + 1}kg
                                                            v zľave</div>
                                                        : <div>{this.state.max}kg v zľave</div>

                                                ]
                                                :
                                                null

                                        }


                                        {this.state.price == "" ?
                                            <p className="price detailprice">od: {this.state.product.pricko}</p>
                                            :
                                            <p className="price detailprice">Cena: {(Math.round(this.state.price * 100) / 100).toFixed(2)} €</p>
                                        }
                                        <div className="addtocart">
                                            <a className="var add-to-cart"
                                               onClick={() => this.addToCart(this.state.product.id, this.state.count, this.state.variation_id, this.state.product.title, this.state.var_price)}>Pridať
                                                do košíka</a>
                                        </div>

                                        {Array.isArray(this.state.product.categories) ?
                                            <Link className="var add-to-cart backbb"
                                                  to={'/?cat=' + this.slugify(this.state.product.categories[0])}>Späť</Link>
                                        : null }

                                    </div>
                              : null  }
                                <br/> <br/>
                            </div>
                            <div className="col-md-12">
                                <h2 className="nadpisProd nadpisDetail">Popis</h2>
                                <br/>
                                <div className="description" dangerouslySetInnerHTML={{__html:this.state.product.description}} />
                                <div className="short_description" dangerouslySetInnerHTML={{__html:this.state.product.short_description}} />
                            </div>

                        </div>
                    </div>

                </div>
            );
        }
        else if((this.state.stock.stock > 0 || this.state.stock.stock == -1 || this.state.stock.allowed == 1) && Array.isArray(this.state.product.variations)){
            return (
            //NORMAAAAAAAAAAAAAAALLLLLLLLLLLLLLLLNEEEEEEEEEEEEEEEEE
                //NORMALLLLLLLLLLLLLLLLLLNEEEEEEEEEEEEEEEEEE
                <div className="productsect">

                    {Array.isArray(this.state.product.categories)?
                    <Link className="backbtn" to={'/?cat='+this.slugify(this.state.product.categories[0])} ><img src={BackBtn}/></Link>
                        : null }
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods odsbot">
                            <div className="col-md-6">
                                <div className="product proddetail"
                                     style={{backgroundImage: `url(${this.state.product.fotkamobil})`}}></div>
                            </div>
                            <div className="col-md-6">
                                <h2 className="nadpisProd nadpisDetail">{this.state.product.title}</h2>
                                {/*{this.state.stock.allowed == 0 ?*/}
                                {/*<p>Na sklade</p>*/}
                                    {/*: <p>Na sklade</p> }*/}

                                <div className="variations">
                                    {/*<h6 className="minihead">Varianta</h6>*/}
                                    {/*<p>Zlava: {this.state.salprice}</p>*/}
                                    {/*<p>Min.  {this.state.min}</p>*/}
                                    {/*<p>Max.  {this.state.max}</p>*/}
                                    <p></p>
                                    {
                                        this.state.product.variations.map((data,index)=> {
                                                if(data.dimensions.width != 1 && data.dimensions.width != 2 && (data.weight <= this.state.stock.stock || this.state.stock.allowed == 1 ))return(
                                                    <a className={this.state.activeClasses[index] ? "active var" : "inactive var"}
                                                       onClick={() => this.addvar(data.id, data.price, data.attributes[0].option, index, data.weight)}>

                                                        {this.addvarfirst(data.id, data.price, data.attributes[0].option, index, data.weight)}
                                                        {data.attributes[0].option}
                                                    </a>)

                                                // if(index >= 6 && data.dimensions.width != 1)return(
                                                //     <a style={{display: this.state.varshow}} className={this.state.activeClasses[index] ? "active var" : "inactive var"}
                                                //        onClick={() => this.addvar(data.id, data.price, this.getVarName(index), index, data.weight)}>
                                                //
                                                //         {this.getVarName(index)}
                                                //     </a>)


                                            }
                                        )
                                    }
                                    {/*{this.state.product.variations.length >= 6 ?*/}
                                        {/*<a  className={this.state.varp ? 'btn btn-primary blocked' : 'btn btn-primary hidden blocked'} onClick={this.showVars}> Zobraziť ďalšie </a> : null*/}
                                    {/*}*/}
                                </div>
                                <div className="count">
                                    <h6 className="minihead">Počet kusov</h6>
                                    <span><a className="minus" onClick={this.decrement}>-</a><a className="incrementing">{this.state.count}</a><a className="plus" onClick={this.increment}>+</a> </span>
                                </div>
                                <br/>
                                {
                                    this.state.salprice != "" && ((this.state.count*this.state.actualWeight1 - this.state.min + 1) >= 0 ) ?[

                                            (this.state.count * this.state.actualWeight1 - this.state.min + 1) <= this.state.max ? <div>{this.state.count * this.state.actualWeight1 - this.state.min + 1}kg v zľave</div>
                                                : <div>{this.state.max}kg v zľave</div>

                                        ]
                                        :
                                        null

                                }


                                {this.state.price == "" ?
                                    <p className="price detailprice">od: {this.state.product.pricko}</p>
                                    :
                                    <p className="price detailprice">Cena: {(Math.round(this.state.price*100)/100).toFixed(2)} €</p>
                                }
                                <div className="addtocart">
                                    <a className="var add-to-cart" onClick={()=>this.addToCart(this.state.product.id,this.state.count,this.state.variation_id, this.state.product.title, this.state.var_price)} >Pridať do košíka</a>
                                </div>

                                {Array.isArray(this.state.product.categories) ?
                                <Link className="var add-to-cart backbb" to={'/?cat='+this.slugify(this.state.product.categories[0])}>Späť</Link>
                                    : null }
                                <br/> <br/>
                            </div>
                            <div className="col-md-12">
                                <h2 className="nadpisProd nadpisDetail">Popis</h2>
                                <br/>
                                <div className="description" dangerouslySetInnerHTML={{__html:this.state.product.description}} />
                                <div className="short_description" dangerouslySetInnerHTML={{__html:this.state.product.short_description}} />
                            </div>

                        </div>
                    </div>

                </div>
            );
        }
        else{
            return(
                <div className="productsect">

                    {Array.isArray(this.state.product.categories)?
                    <Link className="backbtn" to={'/?cat='+this.slugify(this.state.product.categories[0])} ><img src={BackBtn}/></Link>
                        : null }
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods odsbot">
                            <div className="col-md-6">
                                <div className="product proddetail"
                                     style={{backgroundImage: `url(${this.state.product.fotkamobil})`}}></div>
                            </div>
                            <div className="col-md-6">
                                <h2 className="nadpisProd nadpisDetail">{this.state.product.title}</h2>

                                <p>Nie je na sklade</p>
                                {Array.isArray(this.state.product.categories) ?
                                <Link className="var add-to-cart backbb"
                                      to={'/?cat=' + this.slugify(this.state.product.categories[0])}>Späť</Link>
                                    : null}
                            </div>
                            <div className="col-md-12">
                                <h2 className="nadpisProd nadpisDetail">Popis</h2>
                                <br/>
                                <div className="description" dangerouslySetInnerHTML={{__html:this.state.product.description}} />
                                <div className="short_description" dangerouslySetInnerHTML={{__html:this.state.product.short_description}} />
                            </div>

                        </div>
                    </div>

                </div>
            );
        }
    }

    getVarName = (index) => {
        var product = this.state.product;

        return product.attributes[0].options[index];
    }
}
export default Product;