import React, { Component } from 'react';
import Header from '../Header';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


var WooCommerce = require('woocommerce');



var wooCommerce = new WooCommerce({
    url: 'https://www.sladuckeovocie.sk',
    ssl: true,
    consumerKey: 'ck_19db9a693a9bdb2c7b940d0bc1826df582014d6c',
    secret: 'cs_55925e23f14e1fef93d4725a681cdeeca14a0cd9'
});


class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainimage: "",
            text: "",
            cart: "nic",
            products: "",
            prodincart: "nic",
            notship: 0,
            price: 0,
            doprava: 0,
            arship: new Array(),
            arglob: new Array(),
        };
        this.getProductName = this.getProductName.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.recalc = this.recalc.bind(this);
        this.updateCartShip = this.updateCartShip.bind(this);
        this.removecart = this.removecart.bind(this);
        this.handleCartChange = this.handleCartChange.bind(this)



    }
    handleCartChange(novacena,podm){
        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        if((Array.isArray(data))) {


            var x = 0;

            var pric = 0;


            data.forEach(function (e) {
                pric = pric + parseFloat(e[3].PRICE);
                if (x == data.length - 1) {
                    this.updateCartShip(pric, novacena, podm);
                }
                x++;
            }.bind(this));
        }


    }
    decrement(id,sale, count) {
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();

        if(sale == true){

            NotificationManager.warning("Ak chcete zmeniť množstvo tejto položky, zmažte ju a pridajte si ju nanovo v obchode!","Zľavnenú položku nemôžete editovať", 1000);

            return false;
        }


        var count1 = count - 1;

        if(count1 < 1){

            return false;
        }


        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        if(!(Array.isArray(data))){


            data = new Array();
        }
        else{


        }
        var newCart = new Array();
        var exist = 0;
        var fredop = 0;
        var x = 0;
        var price1 = 0;
        var staracena = 0;

        var pric = 0;

        newCart.forEach(function (e) {
            pric = pric + parseFloat(e[3].PRICE);
        });

        data.forEach(function (e) {
            if(e[4].VAR_ID != id || e[6].SALE != sale  ){
                newCart.push(e);
            }



            else{


                var obj = new Array();
                var prevCount = e[1].QUANTITY;

                obj.push({"ID": e[0].ID});
                obj.push({"QUANTITY": count1});
                obj.push({"NAME": e[2].NAME});
                price1 = count1 * (e[3].PRICE / prevCount);
                obj.push({"PRICE": price1});
                obj.push({"VAR_ID":e[4].VAR_ID});
                obj.push({"VAR_NAME":e[5].VAR_NAME});
                obj.push({"SALE":e[6].SALE});
                obj.push({"HEIGHT":e[7].HEIGHT});
                obj.push({"SHIP":e[8].SHIP});
                obj.push({"GLOBAL":e[9].GLOBAL});
                if(e[10].NOTSHIP != 0){
                    obj.push({"NOTSHIP": price1});
                }
                else{
                    obj.push({"NOTSHIP": 0});
                }



                staracena = e[10].NOTSHIP;


                newCart.push(obj);

                if(e[8].SHIP != false){

                    var arko = this.state.arship;



                    arko.forEach(function (ecko) {
                        if(ecko.id == e[0].ID){
                            ecko.quant = ecko.quant +  parseFloat(e[7].HEIGHT);
                        }
                    }.bind(this));



                    this.setState({
                        arship: arko,
                    })

                }

                if(e[9].GLOBAL != false){

                    var arko = this.state.arglob;

                    arko.forEach(function (ecko) {
                        ecko.quant = ecko.quant + parseFloat(e[7].HEIGHT);

                    }.bind(this));

                    this.setState({
                        arship: arko,
                    });

                }

                if(e[8].SHIP != false){
                    if((e[1].QUANTITY-1)*e[7].HEIGHT >= e[8].SHIP){
                        fredop = 1;
                    }
                }

                if(e[9].GLOBAL != false){
                    if((e[1].QUANTITY-1)*e[7].HEIGHT >= e[9].GLOBAL){
                        fredop = 1;
                    }
                }


            }

            if(x==data.length-1){
                NotificationManager.warning('Košík bol aktualizovaný', null, 1000);
                localStorage.setItem('cart', JSON.stringify(newCart));
                this.setState({
                    cart: newCart,
                },()=>{
                    var novacena = this.state.notship - staracena + price1;
                    var podm = 0;
                    if(staracena!=0){
                        podm = 1;
                    }
                    this.handleCartChange(novacena,podm);
                });

            }

            x++;

        }.bind(this));





        var arko = this.state.arship;

        if(this.checkIfFree(arko)){
            fredop = 1;
        }

        //  if(pric >= 100){
        //      fredop=1;
        //  }


        if(pric - this.state.notship <= 10 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                doprava: 9.99,
            });

        }
        else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                doprava: 5.99,
            });

        }
        else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                doprava: 4.99,
            });

        }
        else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                doprava: 3.99,
            });

        }
        else if(pric - this.state.notship >= 60) {
            this.setState({
                price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                doprava: 0,
            });

        }
        else if(fredop == 0){
            this.setState({
                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                doprava: 3.99,
            });
        }
        else{
            this.setState({
                price: (Math.round((pric) * 100) / 100).toFixed(2),
                doprava: 0,
            });
        }




        this.props.recount();


    }

    increment(id,sale, count) {
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();


        if(sale == true){

            NotificationManager.warning("Ak chcete zmeniť množstvo tejto položky, zmažte ju a pridajte si ju nanovo v obchode!","Zľavnenú položku nemôžete editovať", 1000);

            return false;
        }


        var count1 = count+1;

        if(count1 < 1){

            return false;
        }


        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        if(!(Array.isArray(data))){

            data = new Array();
        }
        else{


        }
        var newCart = new Array();
        var exist = 0;
        var fredop = 0;
        var pric = 0;
        var x =0;
        var staracena = 0;

        var price1 = 0;



        newCart.forEach(function (e) {
            pric = pric + parseFloat(e[3].PRICE);
        });
        data.forEach(function (e) {


            if(e[4].VAR_ID != id || e[6].SALE != sale  ){
                newCart.push(e);
            }

            else{


                var obj = new Array();
                var prevCount = e[1].QUANTITY;

                obj.push({"ID": e[0].ID});
                obj.push({"QUANTITY": count1});
                obj.push({"NAME": e[2].NAME});
                price1 = count1 * (e[3].PRICE / prevCount);
                obj.push({"PRICE": price1});
                obj.push({"VAR_ID":e[4].VAR_ID});
                obj.push({"VAR_NAME":e[5].VAR_NAME});
                obj.push({"SALE":e[6].SALE});
                obj.push({"HEIGHT":e[7].HEIGHT});
                obj.push({"SHIP":e[8].SHIP});
                obj.push({"GLOBAL":e[9].GLOBAL});

                if(e[10].NOTSHIP != 0){
                    obj.push({"NOTSHIP": price1});
                }
                else{
                    obj.push({"NOTSHIP": 0});
                }


                if(e[10].NOTSHIP!=0) {

                    staracena = e[10].NOTSHIP;

                }

                newCart.push(obj);


                if(e[8].SHIP != false){

                    var arko = this.state.arship;

                    arko.forEach(function (ecko) {
                        if(ecko.id == e[0].ID){
                            ecko.quant = ecko.quant - parseFloat(e[7].HEIGHT);
                        }
                    }.bind(this));



                    this.setState({
                        arship: arko,
                    })

                }

                if(e[9].GLOBAL != false){

                    var arko = this.state.arglob;

                    arko.forEach(function (ecko) {
                        //      if(ecko.id == e[0].ID){
                        ecko.quant = ecko.quant - parseFloat(e[7].HEIGHT);

                        //     }
                    }.bind(this));

                    //   console.log(arko);



                    this.setState({
                        arglob: arko,
                    })

                }
            }


            if(x==data.length-1){
                NotificationManager.warning('Košík bol aktualizovaný', null, 1000);
                localStorage.setItem('cart', JSON.stringify(newCart));
                this.setState({
                    cart: newCart,
                },()=>{
                    var novacena = this.state.notship - staracena + price1;
                    var podm = 0;
                    if(staracena!=0){
                        podm = 1;
                    }
                    this.handleCartChange(novacena,podm);
                });

            }

            x++;


        }.bind(this));



        var pric = 0;

        newCart.forEach(function (e) {
            pric = pric + parseFloat(e[3].PRICE);
        });

        var arko = this.state.arship;



        if(this.checkIfFree(arko)){
            fredop = 1;
        }

        // if(pric >= 100){
        //     fredop=1;
        // }

        arko = this.state.arglob;

        if(this.checkIfFree(arko)){
            fredop = 1;
        }

        if(pric - this.state.notship <= 10 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                doprava: 9.99,
            });

        }
        else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                doprava: 5.99,
            });

        }
        else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                doprava: 4.99,
            });

        }
        else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
            this.setState({
                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                doprava: 3.99,
            });

        }
        else if(pric - this.state.notship >= 60) {
            this.setState({
                price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                doprava: 0,
            });

        }
        else if(fredop == 0){
            this.setState({
                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                doprava: 3.99,
            });
        }
        else{
            this.setState({
                price: (Math.round((pric) * 100) / 100).toFixed(2),
                doprava: 0,
            });
        }



        this.props.recount();


    }

    removeItem = (id) => {
        var data = localStorage.getItem("cart");
        data = JSON.parse(data);

        NotificationManager.listNotify = [];
        NotificationManager.emitChange();



        if(!(Array.isArray(data))){


            data = new Array();
            this.setState({
                price: 0,
                cart: "nic",
            })
        }

        else{


        }


        var newCart = new Array();
        var exist = 0;

        var price = 0;
        var fredop = 0;

        var pric = 0;

        var x = 0;

        var staracena = 0;

        newCart.forEach(function (e) {
            pric = pric + parseFloat(e[3].PRICE);
        });

        data.forEach(function (e) {
            if(e[4].VAR_ID != id){


                newCart.push(e);

                price = (Math.round(e[3].PRICE*100)/100) + price;




            }
            else{
                if(e[8].SHIP != false){

                    var arko = this.state.arship;



                    arko.forEach(function (ecko) {
                        if(ecko.id == e[0].ID){
                            ecko.quant = ecko.quant + (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT));
                        }
                    }.bind(this));



                    this.setState({
                        arship: arko,
                    })






                }

                if(e[9].GLOBAL != false){

                    var arko = this.state.arglob;



                    arko.forEach(function (ecko) {
                        // if(ecko.id == e[0].ID){
                        ecko.quant = ecko.quant + (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT));
                        //  }
                    }.bind(this));



                    this.setState({
                        arglob: arko,
                    })




                }
                staracena = e[10].NOTSHIP;
            }

            if(x == data.length-1){
                NotificationManager.warning('Košík bol aktualizovaný', null, 1000);
                localStorage.setItem('cart', JSON.stringify(newCart));
                this.setState({
                    cart: newCart,
                },()=>{
                    var novacena = this.state.notship - staracena;
                    var podm = 0;
                    if(staracena!=0){
                        podm = 1;
                    }
                    this.handleCartChange(novacena,podm);
                });

            }
            x++;

        }.bind(this));

        var arko = this.state.arship;

        if(this.checkIfFree(arko)){
            fredop = 1;
        }

        // if(price >= 100){
        //     fredop=1;
        // }


        if( (Math.round(price*100)/100) > 0 ) {
            // if(price - this.state.notship < 10 && fredop == 0) {
            //     this.setState({
            //         price: (Math.round((price + 5.99) * 100) / 100).toFixed(2),
            //         doprava: 5.99,
            //     });
            //
            // }
            // else if(price - this.state.notship < 15 && fredop == 0) {
            //     this.setState({
            //         price: (Math.round((price + 4.99) * 100) / 100).toFixed(2),
            //         doprava: 4.99,
            //     });
            //
            // }
            // else if(price - this.state.notship >= 15 && fredop == 0) {
            //     this.setState({
            //         price: (Math.round((price + 3.99) * 100) / 100).toFixed(2),
            //         doprava: 3.99,
            //     });
            //
            // }
            // else if(fredop == 0){
            //     this.setState({
            //         price: (Math.round((price + 3.99) * 100) / 100).toFixed(2),
            //         doprava: 3.99,
            //     });
            // }
            // else{
            //     this.setState({
            //         price: (Math.round((price) * 100) / 100).toFixed(2),
            //         doprava: 0,
            //     });
            // }
        }
        else{





            localStorage.removeItem("cart");
            this.setState({
                price: 0,
                cart: "nic",
            });

            this.props.recount();

            NotificationManager.warning('Položka bola zmazaná', null, 3000);

            return;
        }


        localStorage.setItem('cart', JSON.stringify(newCart));
        this.setState({
            cart: newCart,
        });

        this.props.recount();
        NotificationManager.warning('Položka bola zmazaná', null, 3000);

    }

    getProductName = (id, type) => {

        if (this.state.products != "") {

            this.state.products.forEach(function (e) {
                if (e.id == id) {
                    if (type == 'title') {

                        return e.title;
                    }

                }
            });
        } else {
            return;
        }
    }

    getName = (array) => {
        return "ide";
    }

    removecart = () => {
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();
        localStorage.removeItem("cart");
        this.setState({
            cart: "nic",
        });
        NotificationManager.error('Košík bol zmazaný', null, 3000);

        this.props.recount();
    }

    recalc(){

        // let cart = localStorage.getItem("cart");
        //
        // var pric = 0;
        // var fredop = 0;
        //
        // if(Array.isArray(cart)) {
        //     cart.forEach(function (e) {
        //
        //         pric = pric + parseFloat(e[3].PRICE);
        //
        //         if(e[8].SHIP != false){
        //             if(e[1].QUANTITY*e[7].HEIGHT >= e[8].SHIP){
        //                 fredop = 1;
        //
        //             }
        //         }
        //
        //     }.bind(this));
        // }
        //
        //
        //
        // if(pric <= 10 && fredop == 0) {
        //     this.setState({
        //         price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
        //         doprava: 4.99,
        //     });
        //     NotificationManager.warning("","Upozorňujeme vás že pre objednávky do 10€ je účtovana doprava a balné v cene 4.99€", 3000);
        // }
        // else if(fredop == 0){
        //     this.setState({
        //         price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
        //         doprava: 3.99,
        //     });
        // }
        // else{
        //     this.setState({
        //         price: (Math.round((pric) * 100) / 100).toFixed(2),
        //         doprava: 0,
        //     });
        // }
        //



    }

    updateCartShip = (pric, nshiping,podm) => {
        console.log(nshiping);
        var fredop=0;
        if(podm==1) {
            this.setState({
                notship: nshiping,
            }, () => {

                if(pric - this.state.notship <= 10 && fredop == 0) {
                    this.setState({
                        price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                        doprava: 9.99,
                    });

                }
                else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
                    this.setState({
                        price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                        doprava: 5.99,
                    });

                }
                else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
                    this.setState({
                        price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                        doprava: 4.99,
                    });

                }
                else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
                    this.setState({
                        price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                        doprava: 3.99,
                    });

                }
                else if(pric - this.state.notship >= 60) {
                    this.setState({
                        price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                        doprava: 0,
                    });

                }
                else if(fredop == 0){
                    this.setState({
                        price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                        doprava: 3.99,
                    });
                }
                else{
                    this.setState({
                        price: (Math.round((pric) * 100) / 100).toFixed(2),
                        doprava: 0,
                    });
                }
            });
        }
        else{
            if(pric - this.state.notship <= 10 && fredop == 0) {
                this.setState({
                    price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                    doprava: 9.99,
                });

            }
            else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
                this.setState({
                    price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                    doprava: 5.99,
                });

            }
            else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
                this.setState({
                    price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                    doprava: 4.99,
                });

            }
            else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
                this.setState({
                    price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                    doprava: 3.99,
                });

            }
            else if(pric - this.state.notship >= 60) {
                this.setState({
                    price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                    doprava: 0,
                });

            }
            else if(fredop == 0){
                this.setState({
                    price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                    doprava: 3.99,
                });
            }
            else{
                this.setState({
                    price: (Math.round((pric) * 100) / 100).toFixed(2),
                    doprava: 0,
                });
            }
        }
    }


    componentDidMount() {
        NotificationManager.listNotify = [];
        NotificationManager.emitChange();

        window.scrollTo(0, 0);
        axios.get('https://www.sladuckeovocie.sk/wp-json/acf/v3/pages')
            .then(res => {

                this.setState({
                    mainimage: res.data[0]['acf']['obrazok'],
                    text: res.data[0]['acf']['nadpis'],
                });
            });

        let cart = localStorage.getItem("cart");
        if (cart != null) {
            cart = JSON.parse(cart);
            this.setState({
                cart: cart,
            });

            var newp = "";

            var mapper = new Array();



        }

        var pric = 0;
        var fredop = 0;

        var x = 0;

        var nshiping = 0;


        if(Array.isArray(cart)) {
            cart.forEach(function (e) {


                var nship = e[10].NOTSHIP;
                pric = pric + parseFloat(e[3].PRICE);

                nship = parseFloat(nship);

                if(nship!=0) {

                    nshiping = nshiping + parseFloat(nship);

                    this.setState({
                        notship: this.state.notship + parseFloat(nship),
                    },()=>{
                        if(pric - this.state.notship <= 10 && fredop == 0) {
                            this.setState({
                                price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                                doprava: 9.99,
                            });

                        }
                        else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
                            this.setState({
                                price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                                doprava: 5.99,
                            });

                        }
                        else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
                            this.setState({
                                price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                                doprava: 4.99,
                            });

                        }
                        else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
                            this.setState({
                                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                                doprava: 3.99,
                            });

                        }
                        else if(pric - this.state.notship >= 60) {
                            this.setState({
                                price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                                doprava: 0,
                            });

                        }
                        else if(fredop == 0){
                            this.setState({
                                price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                                doprava: 3.99,
                            });
                        }
                        else{
                            this.setState({
                                price: (Math.round((pric) * 100) / 100).toFixed(2),
                                doprava: 0,
                            });
                        }



                    });



                }
                else{
                    if(pric - this.state.notship <= 10 && fredop == 0) {
                        this.setState({
                            price: (Math.round((pric + 9.99) * 100) / 100).toFixed(2),
                            doprava: 9.99,
                        });

                    }
                    else if(pric - this.state.notship > 10 && pric-this.state.notship <=15 && fredop == 0) {
                        this.setState({
                            price: (Math.round((pric + 5.99) * 100) / 100).toFixed(2),
                            doprava: 5.99,
                        });

                    }
                    else if(pric - this.state.notship > 15 && pric-this.state.notship <=20 && fredop == 0) {
                        this.setState({
                            price: (Math.round((pric + 4.99) * 100) / 100).toFixed(2),
                            doprava: 4.99,
                        });

                    }
                    else if(pric - this.state.notship > 20 && pric-this.state.notship <60 && fredop == 0) {
                        this.setState({
                            price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                            doprava: 3.99,
                        });

                    }
                    else if(pric - this.state.notship >= 60) {
                        this.setState({
                            price: (Math.round((pric + 0) * 100) / 100).toFixed(2),
                            doprava: 0,
                        });

                    }
                    else if(fredop == 0){
                        this.setState({
                            price: (Math.round((pric + 3.99) * 100) / 100).toFixed(2),
                            doprava: 3.99,
                        });
                    }
                    else{
                        this.setState({
                            price: (Math.round((pric) * 100) / 100).toFixed(2),
                            doprava: 0,
                        });
                    }
                }




                if(e[8].SHIP != false){

                    var arko = this.state.arship;

                    if(!this.keyExists(e[0].ID, arko)) {


                        var js = {
                            "id": e[0].ID,
                            "quant": parseFloat(e[8].SHIP) - (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT)),
                        };
                        arko.push(js);

                    }
                    else{
                        arko.forEach(function (ecko) {
                            if(ecko.id == e[0].ID){
                                ecko.quant = ecko.quant - (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT));
                            }
                        }.bind(this));

                    }

                    this.setState({
                        arship: arko,
                    })





                    if(e[8].SHIP!=false){
                        if(this.checkIfFree(arko)){
                            fredop = 1;
                        }
                    }
                }

                if(e[9].GLOBAL != false){


                    var arko = this.state.arglob;

                    if(arko.length == 0) {
                        var js = {
                            "id": e[0].ID,
                            "quant": parseFloat(e[9].GLOBAL) - (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT)),
                        };
                        arko.push(js);
                    }
                    else{

                        arko.forEach(function (ecko) {
                            //   if(ecko.id == e[0].ID){
                            ecko.quant = ecko.quant - (parseFloat(e[1].QUANTITY) * parseFloat(e[7].HEIGHT));
                            // }
                        }.bind(this));

                    }

                    this.setState({
                        arglob: arko,
                    });

                    if(e[9].GLOBAL != false){

                        if(this.checkIfFree(arko)){
                            fredop = 1;
                        }

                    }

                }

                if(x == cart.length-1){
                    this.updateCartShip(pric,nshiping,1);
                }


                x++;

            }.bind(this));

        }


        // if(pric >= 100){
        //     fredop=1;
        // }











        let products = localStorage.getItem("products");
        if (products != null) {
            products = JSON.parse(products);

            this.setState({
                products: products,
            });


        }









    }

    checkIfFree(ar){

        var zdarma = 0;
        ar.forEach(function (e) {

            if(e.quant <= 0){

                zdarma = 1;
            }
        }.bind(this));
        if(zdarma == 1){
            return true;
        }
        else{
            return false;
        }
    }


    keyExists(key, search) {
        var exist = 0;
        search.forEach(function (e) {
            if(e.id == key){

                exist = 1;
            }
        }.bind(this));

        if(exist == 1){
            return true;
        }
        else {
            return false;
        }
    }

    render() {

        if (this.state.cart == "nic") {
            return (
                <div className="cart">
                    <NotificationContainer/>

                    <section>
                        <div className="container">
                            <div className="row ods odsbot kosods">
                                <div className="col-md-12 centertext">
                                    <h4 class="headline">Košík</h4>
                                    <br/><br/>
                                    <h1 className="nadpis centertext"> Váš košík je prázdny</h1>
                                    <Link className="btn btn-primary" to="/">Nakupovať</Link>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }

        else {
            return (
                <div className="cart">
                    <NotificationContainer/>

                    <section>
                        <div className="container">
                            <div className="row ods odsbot kosods">
                                <div className="col-md-12">
                                    <h4 class="headline">Košík</h4>
                                    <br/><br/>

                                    <table className="table table-stripped">
                                        <thead>
                                        <tr>
                                            <th>Názov</th>
                                            <th>Množstvo</th>

                                            <th>Cena</th>
                                            <th>Zmazať</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.cart.map(data =>
                                            <tr>
                                                <td className="stop">

                                                    {data[2].NAME} - {data[5].VAR_NAME}
                                                </td>
                                                <td className="centtd">
                                                    {data[6].SALE == false ?

                                                        <span><a className="minus prodd" onClick={()=>this.decrement(data[4].VAR_ID,data[6].SALE, data[1].QUANTITY)}>-</a><a
                                                            className="incrementing prodd">{data[1].QUANTITY}</a><a className="plus prodd"
                                                                                                                    onClick={()=>this.increment(data[4].VAR_ID,data[6].SALE, data[1].QUANTITY)}>+</a> </span>

                                                        : <p>{data[1].QUANTITY}</p> }
                                                </td>

                                                <td className="cenakos">
                                                    {(Math.round((data[3].PRICE)*100)/100).toFixed(2) } €
                                                </td>
                                                <td>
                                                    <a className="btn btn-primary" onClick={()=>this.removeItem(data[4].VAR_ID)}> X</a>
                                                </td>
                                            </tr>
                                        )

                                        }
                                        { this.state.price > 0 ?
                                            <tr>
                                                <td>Doprava a balné</td>
                                                <td></td>


                                                <td>{this.state.doprava} €</td>


                                                <td>

                                                </td>
                                            </tr>
                                            : null
                                        }
                                        </tbody>
                                        <tfoot>
                                        { this.state.price > 0 ?
                                            <tr>
                                                <td>Celková suma</td>
                                                <td></td>
                                                <td>{this.state.price} €</td>
                                                <td></td>
                                            </tr>
                                            : null
                                        }

                                        </tfoot>
                                    </table>
                                    {/*<a onClick={this.removecart} className="btn btn-primary fl">Zmazať košík</a>*/}
                                    <Link to="/pokladna" className="btn btn-primary fr">Pokračovať</Link>


                                </div>
                                <div className="col-md-12">
                                    <br/>
                                    <Link to="/" className="btn btn-primary fl">Naspäť do obchodu</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}

export default Cart;

